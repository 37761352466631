import {filter, map, switchMap} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {of} from "rxjs";
import {FETCH_MODELS} from "../actions/modelActions";
import {change, formValueSelector} from "redux-form";
import {addModelsToBrand} from "../actions/categoryOptionActions";
import {generateAuthHeaders} from "../../helpers/authHelper";
import {modelQueryUrl} from "../urls/modelUrls";


const getSelectedCategoryFromFrom = (state) => {
    const selector = formValueSelector('updateItemV2');
    return selector(state.value, "category");
};

const fetchModels = (action$, state$) =>
    action$.ofType(FETCH_MODELS).pipe(
        switchMap(({brandId, categoryId}) => {
            return ajax.getJSON(modelQueryUrl(brandId, categoryId), generateAuthHeaders(state$)).pipe(
                map(resp => {
                    return addModelsToBrand(categoryId, resp.data);
                })
            )
        })
    );

const fetchModelsOnBrandChange = (action$, state$) =>
    action$.ofType("@@redux-form/CHANGE").pipe(
        filter(action => {
            return action.meta.form === 'updateItemV2';
        }),
        filter(action => {
            return action.meta.field === 'brand.brand';
        }),
        switchMap((action) => {
                let selectedCategory = getSelectedCategoryFromFrom(state$);
                const brandId = action.payload;

                return ajax.getJSON(modelQueryUrl(brandId, selectedCategory.id), generateAuthHeaders(state$)).pipe(
                    map(resp => {
                        return addModelsToBrand(selectedCategory.id, resp.data);
                    })
                )

            }
        )
    );


const handleBrandChange = (action$) =>
    action$.ofType("@@redux-form/CHANGE").pipe(
        filter(action => {
            return action.meta.form === 'updateItemV2';
        }),
        filter(action => {
            return action.meta.field === 'brand.brand';
        }),
        switchMap(action => {
            return  of(change("updateItem", "model", "", false))
        })
    );

export const modelEpics = [
    fetchModels,
    fetchModelsOnBrandChange,
    handleBrandChange
];
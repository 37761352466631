import {interval} from 'rxjs';
import {delayWhen, map, take} from 'rxjs/operators';
import {ADD_FLASH_MESSAGE, removeFlashMessage} from "../actions/flashMessageActions";

const clearFlashMessage = (action$) => {
    return action$.ofType(ADD_FLASH_MESSAGE).pipe(

        delayWhen(action => interval(action.delay).pipe(take(1))),
        map(action => removeFlashMessage(action.key))
    )
};

// const clearFlashMessageOnRouteChange = (action$, state) => {
//     return action$.ofType("@@router/LOCATION_CHANGE")
//         .map(() => {
//             return clearAllFlashMessages()
//         })
// };

export const flashMessageEpics = [
    clearFlashMessage,
    // clearFlashMessageOnRouteChange
];
import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class FlashMessages extends React.Component {
    render() {
        const {flashMessage, match: {params: {itemId}}} = this.props;

        return (
            <>
                {flashMessage[`itemUpdated-${itemId}`] && (
                    <div className="message is-success">
                        <div className="message-body">
                            {flashMessage[`itemUpdated-${itemId}`]}
                        </div>
                    </div>
                )}
                {flashMessage[`itemUpdateFailed-${itemId}`] && (
                    <div className="message is-danger">
                        <div className="message-body">
                            {flashMessage[`itemUpdateFailed-${itemId}`]}
                        </div>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = (state, props) => {

    const flashMessage = state.flashMessage;

    return {flashMessage};
};
const ConnectedFlashMessages = withRouter(connect(mapStateToProps)(FlashMessages));
export default ConnectedFlashMessages;
import React from 'react';
import renderInput from "./common/form/renderInput";
import {change, Field, reduxForm} from "redux-form";
import {attemptAuthentication, setRecaptchaToken} from "../store/actions/authenticationActions";
import {connect} from "react-redux";
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-v3'
import {recaptchaKey} from "./common/getEnv";


class Login extends React.Component {
    constructor() {
        super();
        this.state = {};

    }
    componentDidMount() {
        loadReCaptcha(recaptchaKey);
    }

    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        console.log(recaptchaToken)
        this.props.setRecaptchaToken(recaptchaToken)
    }

    render() {
        const {handleSubmit, attemptAuthentication, message} = this.props;

        return (
            <div className="columns is-centered">
                <div className="column is-half-tablet">
                    {message &&
                    <div className="message is-danger">
                        <div className="message-body">{message}</div>
                    </div>
                    }

                    <ReCaptcha
                        sitekey={recaptchaKey}
                        action='login'
                        verifyCallback={this.verifyCallback}
                    />
                    <form onSubmit={handleSubmit(attemptAuthentication)}>
                        <Field component={renderInput} name="username" placeholder="Username or Email" label="Login"/>
                        <Field component={renderInput} name="password" type="password" placeholder="Password"
                               label="Password"/>

                        <div className="field">
                            <p className="control">
                                <button className="button is-primary">Login</button>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

Login = reduxForm({form: 'loginForm', enableReinitialize: true})(Login);

const mapStateToProps = (state) => {
    let message = state.authentication.message;

    return {
        message
    }
};
const ConnectedLogin = connect(mapStateToProps, {attemptAuthentication, setRecaptchaToken})(Login);
export default ConnectedLogin;
import React from 'react';
import ItemRow from "./ItemRow";

class ItemTable extends React.Component {
    render() {
        const {items = []} = this.props;
        return (
            <table className="table is-striped is-hoverable is-fullwidth">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Seller</th>
                    <th>Name</th>
                    <th>Category</th>
                    <th>State</th>
                    <th>Published</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {items.map(item => <ItemRow key={`ir-${item.id}`} item={item}/>)}
                </tbody>
            </table>
        )
    }
}

export default ItemTable;
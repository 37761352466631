import {produce} from 'immer';
import {ADD_DETAILS_TO_CATEGORY, ADD_MODELS_TO_BRAND, NO_OPTIONS_FOUND} from "../../actions/categoryOptionActions";
import {V2_LOAD_ITEM_DETAILS} from "../../actions/v2/itemActions";
import {REDUX_FORM_CHANGE} from "../../epics/detailEpics";


const defaultState = {
    status: "not-fetched",
    categories: {}
};

const categoryOptionReducer = () => (
    state = defaultState,
    action
) => produce(state, draft => {


    switch (action.type) {
        case REDUX_FORM_CHANGE:
            if (action.meta.form === 'updateItemV2' && action.meta.field === 'category') {
                draft.status = 'fetching';

            }
            return;
        case V2_LOAD_ITEM_DETAILS:
            draft.status = 'fetching';
            return;
        case NO_OPTIONS_FOUND:
            draft.status = 'fetched';
            return;
        case ADD_DETAILS_TO_CATEGORY:
            if (action.categoryId === undefined) {
                draft.status = 'fetched';
                return;
            }

            if (!draft.categories[action.categoryId]) {
                draft.categories[action.categoryId] = {};
            }
            draft.categories[action.categoryId].brand = action.details.find(d => d.slug === 'brand');
            draft.categories[action.categoryId].details = action.details.filter(d => d.slug !== 'brand' && d.slug !== 'category');
            draft.status = 'fetched';
            return;

        case ADD_MODELS_TO_BRAND:
            if (action.categoryId === undefined) {
                draft.status = 'fetched';
                return;
            }

            if (!draft.categories[action.categoryId]) {
                draft.categories[action.categoryId] = {};
            }
            draft.categories[action.categoryId].models = action.models;
            draft.status = 'fetched';
            return;
        default:
            return;
    }
});

export default categoryOptionReducer;
import React from 'react';
import {Loading} from "../../../../common/Loading";
import {Field, FormSection} from "redux-form";
import {renderDropdown} from "../../../../common/form/renderDropdownList";

class ItemDetails extends React.Component {

    renderHelpMessage = () => {
        const { variationState: {can_have_variations, variationSelection}} = this.props;

        if(can_have_variations && variationSelection === 'variation') {
            return <p className="help is-info">Disabled detail selectors must be set for each variation.</p>
        } else {
            return null;
        }
    };

    detailIsLocked = (detail) => {
        const {variationState: {detailVariations, itemHasVariations, variationSelection}} = this.props;

        return variationSelection === 'variation' && detailVariations.includes(detail.slug)
    };

    render() {
        const {options = {}, optionStatus, item} = this.props;
        const title = <h2 className="subtitle is-4">Item Details</h2>;
        if (optionStatus !== 'fetched') {
            return <div className="box">{title}<Loading/></div>
        }

        const {details = []} = options;

        return (
            <div className="box">
                {title}
                <FormSection name="details" className="columns is-multiline is-variable is-3">
                    {details.map(detail => {
                        let fieldName = detail.required ? `${detail.name} (Required)` : detail.name;

                        return (
                            <div key={`dtl-${detail.slug}-${item.id}`} className="column is-one-third">
                                <Field name={detail.slug} label={fieldName} disabled={this.detailIsLocked(detail)}
                                       component={renderDropdown}
                                       data={detail.children} normalize={v => v && v.id}/>
                            </div>
                        )
                    })}
                </FormSection>
                {this.renderHelpMessage()}
            </div>
        )
    }
}

export default ItemDetails;
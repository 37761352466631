import React from 'react';
import {Field} from "redux-form";
import renderInputCash from "../../../../common/form/renderInputCash";
import renderInput from "../../../../common/form/renderInput";


class PricingInfo extends React.Component {

    render() {
        const {item: {seller: {fixed_price_listing}}, variationState: {variationSelection}} = this.props;


        return (
            <div className="columns is-multiline">
                <div className="column is-one-third">
                    <Field name="list_price" label="Price" component={renderInputCash}/>
                </div>
                <div className="column is-one-third">
                    <Field name="price_retail" label="Retail Price"
                           component={renderInputCash}/>
                </div>
                {variationSelection === 'single' &&
                <div className="column is-one-third">
                    <Field type="number" name="quantity" label="Quantity" component={renderInput}/>
                </div>
                }

                {fixed_price_listing && <div className="column is-3">
                    <div className="field">
                        <label className="label">Accept Offers&nbsp;&nbsp;
                            <Field name="accepts_offers" label="Inventory ID" type="checkbox" component="input"/>
                        </label>
                    </div>
                </div>}
            </div>
        )
    }
}


export default PricingInfo;
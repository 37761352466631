export const generateParcelPayload = (data) => {
    const {parcel = {}} = data;
    return {
        parcel: {
            length: parcel.length || null,
            width: parcel.width || null,
            height: parcel.height || null || null,
            weight_lbs: parcel.weight_lbs || null,
            weight_oz: parcel.weight_oz || null
        }
    }
};

export const generateItemUpdatePayload = (data, parcelId) => {
    let details = Object.keys(data.details).map(f => data.details[f]);

    if (data.brand.brand) {
        details.push(data.brand.brand);
    }

    let {privateDetails = {}} = data;

    let itemPayload =  {
        name: data.name,
        quantity: data.quantity,
        detail_ids: details,
        category_ids: [data.category.id],
        primary_category_id: data.category.id,
        description: data.description,
        list_price: data.list_price,
        price_retail: data.price_retail,
        parcel_id: parcelId,
        ebay_id: privateDetails.ebay_id,
        gtin: privateDetails.gtin,
        mpn: privateDetails.mpn,
        cost: privateDetails.cost,
        inventory_id: privateDetails.inventory_id,
        accepts_offers: data.accepts_offers
    };

    if(data.model) {
        itemPayload.model_id = data.model.id;
    }

    return {
        item: itemPayload
    };
};
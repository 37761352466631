import React from 'react';
import {DropdownList} from 'react-widgets';

export const renderDropdownList = ({
                                       input,
                                       data,
                                       valueField,
                                       textField,
                                       placeholder,
                                       filterSelection = false,
                                       onSearch,
                                       filterType = "startsWith",
                                       disabled = false
                                   }) => {
    let tf;


    if (typeof textField === 'string') {
        tf = item => {
            if (item.hasOwnProperty(textField)) {
                return item[textField];
            } else {
                return null;
            }
        };
    } else {
        tf = textField;
    }

    if (filterSelection) {
        return (
            <DropdownList
                {...input}
                data={data}
                valueField={valueField}
                textField={tf}
                onChange={input.onChange}
                placeholder={placeholder}
                onBlur={e => e.preventDefault()}
                filter={filterType}
                onSearch={onSearch}
                disabled={disabled}
            />
        );
    }
    return (
        <DropdownList
            {...input}
            data={data}
            valueField={valueField}
            textField={tf}
            onChange={input.onChange}
            placeholder={placeholder}
            onSearch={onSearch}
            disabled={disabled}
        />
    );
};

export const renderDropdown = ({data, input, label, disabled = false, type, meta: {touched, error}, filterSelection = false, filterType = 'startsWith', textField = "name", valueField = "id"}) => {
    let props = {
        input: input,
        textField,
        valueField,
        data: data,
        filterSelection,
        filterType,
        disabled
    };


    return (
        <div className="field">
            <div className="control">
                <label className="label">{label}</label>
                {renderDropdownList(props)}
                {touched && error && <p className="help is-danger">{error}</p>}
            </div>
        </div>
    )
};
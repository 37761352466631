/*
<div class="field">
  <p class="control has-icons-left has-icons-right">
    <input class="input" type="email" placeholder="Email">
    <span class="icon is-small is-left">
      <i class="fas fa-envelope"></i>
    </span>
    <span class="icon is-small is-right">
      <i class="fas fa-check"></i>
    </span>
  </p>
</div>
 */

import React from 'react';

const renderInputCash = ({input, label, placeholder = "", autoComplete = "", type, meta: {touched, error}, tabIndex = 0, elementClassName = "field"}) => (
        <div className={elementClassName}>
            <span className="control has-icons-left has-icons-right">
                <label className="label">{label}</label>

                    <input {...input} placeholder={placeholder || label} type="number" className="input" tabIndex={tabIndex}
                           autoComplete={autoComplete}/>
                    <span className="icon is-small is-left"><i className="fas fa-dollar-sign"/></span>
                    {touched && error && <span className="help is-danger">{error}</span>}

            </span>
        </div>
    )
;

export default renderInputCash
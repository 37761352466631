import * as schema from '../../config/schema';
import {normalize} from 'normalizr';

export const FETCH_USERS_FOR_FILTER = "FETCH_USERS_FOR_FILTER";
export const SAVE_USERS_FOR_FILTER = "SAVE_USERS_FOR_FILTER";
export const USERS_FETCH_FOR_FILTER_FAILURE = "USERS_FETCH_FOR_FILTER_FAILURE";

export const fetchUsersForFilter = (term) => ({
    type: FETCH_USERS_FOR_FILTER,
    term
});


export const saveUsersForFilter = (users) => ({
    type: SAVE_USERS_FOR_FILTER,
    userList: normalize(users, schema.arrayOfUsers),

});

import {combineEpics} from "redux-observable";
import {of} from "rxjs";
import {stopSubmit} from "redux-form";
import {itemListEpics} from "../store/epics/itemEpics";
import {authenticationEpics} from "../store/epics/authenticationEpics";
import {userEpics} from "../store/epics/userEpics";
import {categoryEpics} from "../store/epics/categoryEpics";
import {detailEpics} from "../store/epics/detailEpics";
import {modelEpics} from "../store/epics/modelEpics";
import {flashMessageEpics} from "../store/epics/flashMessageEpics";
import {authUrl} from "../components/common/getEnv";
import {v2CategoryEpics} from "../store/epics/v2/categoryEpics";
import {v2DetailEpics} from "../store/epics/v2/detailEpics";
import {v2ItemEpics} from "../store/epics/v2/itemEpics";


export const createAuthUrl = (path) => `${authUrl}${path}`;


export const rootEpic = combineEpics(
    ...authenticationEpics,
    ...itemListEpics,
    ...userEpics,
    ...categoryEpics,
    ...detailEpics,
    ...modelEpics,
    ...flashMessageEpics,


    ...v2ItemEpics,
    ...v2CategoryEpics,
    ...v2DetailEpics
);


// helper to dispatch errors to a redux form
export const dispatchErrorsForForm = (categoryOptions, errorList, form) => {
    if (errorList && errorList.length > 0) {
        let errors = {
            brand: {},
            details: {},
            privateDetails: {},
            parcel: {}
        };

        // parcel error
        if(errorList.some(f => ['length', 'width', 'height', 'weight'].includes(f.field) )) {

            errorList.filter(f => ['length', 'width', 'height', 'weight'].includes(f.field)).forEach (e => {
                if(e.field === 'weight') {
                    errors.parcel.weight_lbs = e.message;
                    errors.parcel.weight_oz = e.message;
                }
                errors.parcel[e.field] = e.message;
            })

        }

        // brand error
        if(errorList.some(f => f.field === 'brand')) {
            errors.brand.brand = "This is required"
        }

        errorList.filter(f => f.field !== 'brand').forEach(error => {
            if(categoryOptions.details.map(d => d.slug).includes(error.field)) {
                errors.details[error.field] = error.message;
            }
        });

        errorList.filter(e => {
            return e.field !== "brand" && !categoryOptions.details.map(d => d.slug).includes(e.field)
        }).forEach(error => {
            if(["gtin", "mpn", "inventory_id", "my_cost"].includes(error.field)) {
                errors.privateDetails[error.field] = error.message
            } else {
                errors[error.field] = error.message
            }
        });

        return of(stopSubmit(form, errors))
    }
};
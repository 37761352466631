import {applyMiddleware, compose, createStore} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router'

import rootReducer from "./createRootReducer";
import {rootEpic} from "./rootEpic";
import {setInitialTokenState} from "../store/actions/authenticationActions";
import {convertParamsToFilter} from "../store/reducer/filter";
import {setInitialFilter} from "../store/actions/filterActions";

export const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export default function configureStore() {
    const store = createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history),
                epicMiddleware
            )
        )
    );

    epicMiddleware.run(rootEpic);

    // Would there be a case when only ONE of the following cookies exists?
    let access_token = getCookieValue('access_token');
    let refresh_token = getCookieValue('refresh_token');

    if(access_token && refresh_token) {
        store.dispatch(setInitialTokenState(access_token, refresh_token))
    }

    // if on the home page, set the initial filter
    if(store.getState().router.location.pathname === "/") {
        const params = new URLSearchParams(store.getState().router.location.search);
        let filter = convertParamsToFilter(params);

        store.dispatch(setInitialFilter(filter));
    }

    return store;
}


const getCookieValue = (cookieName) => {
    const cookie = document.cookie.split(';').filter((item) => item.trim().startsWith(`${cookieName}=`));
    if (cookie.length) {
        return cookie[0].split('=')[1];
    } else {
        return undefined;
    }
};
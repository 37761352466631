import {produce} from 'immer';
import {
    SAVE_USERS_FOR_FILTER,

} from '../../actions/userFilterActions';
import forOwn from 'lodash/forOwn';

const byId = produce((draft, action) => {
    switch (action.type) {
        case SAVE_USERS_FOR_FILTER:
            forOwn(action.userList.entities.user, (value, key) => {
                draft[key] = value;
            });
            return;
        default:
            return draft;
    }
}, {});

export default byId;

export const getUser = (state, id) => state[id];

import React from 'react'
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import {history} from "../config/store";
import Navbar from "./layout/Navbar";
import {ConnectedRouter} from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import Login from "./Login";

const Root = () => (


        <ConnectedRouter history={history}>
            <Navbar/>
           <section className="section">
               <div className="container-fluid">
                   <Switch>
                       <Route path="/login" component={Login}/>
                       <AuthenticatedRoutes/>
                   </Switch>
               </div>
           </section>
        </ConnectedRouter>
);

export default Root;

import {combineReducers} from "redux";
import {history} from "./store"
import {reducer as formReducer} from 'redux-form'
import {connectRouter} from 'connected-react-router'
import itemReducer from '../store/reducer/item';
import userFilterReducer from '../store/reducer/userFilter';
import categoryReducer from '../store/reducer/category'
import {authenticationReducer} from "../store/reducer/authentication";
import {authenticatedUserReducer} from "../store/reducer/authenticatedUser";
import categoryOptionReducer from "../store/reducer/categoryOption";
import flashMessage from "../store/reducer/flashMessage";
import paginationReducer from "../store/reducer/pagination";
import filterReducer from "../store/reducer/filter";
import {LOGOUT} from "../store/actions/authenticationActions";

const reducers = () =>  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    item: itemReducer,
    authentication: authenticationReducer,
    authenticatedUser: authenticatedUserReducer(),
    category: categoryReducer,
    categoryOptions: categoryOptionReducer(),
    flashMessage: flashMessage,
    pagination: paginationReducer,
    filter: filterReducer,
    userFilter: userFilterReducer

});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT || (action.type === '@@router/LOCATION_CHANGE' && action.payload.location.pathname === '/login')) {
        state = undefined
    }

    return reducers()(state, action)
};

export default rootReducer;
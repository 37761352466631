import React from 'react';
import moment from 'moment';
import {DATE_FORMAT} from "../../common/dateFormat";
import {Link} from "react-router-dom";

class ItemRow extends React.Component {
    render() {
        const {item} = this.props;

        const createdAt = moment(item.created_at).format(DATE_FORMAT);
        const updatedAt = moment(item.updated_at).format(DATE_FORMAT);
        const publishedAt = item.published_at && moment(item.published_at).format(DATE_FORMAT);

        const categorySlug = item.categories[0] && item.categories[0].slug;

        return (
            <tr>
                <td>{item.id}</td>
                <td>{item.seller.username}</td>
                <td>{item.name}</td>
                <td>{categorySlug}</td>
                <td>{item.state}</td>
                <td>{publishedAt}</td>
                <td>{createdAt}</td>
                <td>{updatedAt}</td>
                <td><Link to={`/item/${item.id}`}><span className="icon"><i className="far fa-edit"/></span>Edit</Link></td>
            </tr>
        )
    }
}
export default ItemRow;
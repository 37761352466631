const getEnv = (prop) => {
    let envs;
    if (typeof window === 'undefined') {
        envs = process.env;
    } else if (typeof window._env_ === 'object') {
        envs = window._env_;
    } else {
        envs = process.env;
    }

    if (!prop) {
        return envs;
    }

    return envs[prop];
};

export default getEnv;


export const apiUrl = getEnv("REACT_APP_API_URL");
export const authUrl = getEnv("REACT_APP_AUTH_URL");
export const recaptchaKey = getEnv("RECAPTCHA_SITE_KEY");
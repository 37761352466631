import React from 'react';
import {renderDropdown} from "../../../../common/form/renderDropdownList";
import {Field, FormSection} from "redux-form";
import {Loading} from "../../../../common/Loading";

class CategorySelection extends React.Component {
    variationControlLocked = () => {
        const {variationState} = this.props;

        return variationState.itemHasVariations && variationState.can_have_variations;
    };


    showModels = () => {
        const {selectedCategory, options = {}} = this.props;
        let models = options.models;

        if (selectedCategory.has_models) {
            return <Field name="model" label="Model" component={renderDropdown}
                          data={models}/>
        }
    };

    renderBrand = () => {
        const {options = {}} = this.props;

        let brand = options.brand;

        if (brand) {
            let fieldName = brand.required ? `Brand (Required)` : "Brand";
            return <Field name="brand" label={fieldName} component={renderDropdown} textField="name"
                          data={brand.children} normalize={v => v && v.id} filterSelection="true" filterType="contains"/>
        }
    };

    render() {
        const {categories = [], optionStatus, variationState, category_locked_selector} = this.props;

        return (
            <div className="box">
                <h2 className="subtitle is-4">Categories</h2>
                <Field name="category" label="Category" component={renderDropdown} data={categories}
                       textField="full_name" filterSelection="true" filterType="contains" disabled={category_locked_selector}/>

                {variationState.can_have_variations && variationState.variationSelection === 'variation' && (
                    <>
                        <div className="field">
                            <p className="control">
                                <Field name="categories_locked" label="Categories Locked" type="checkbox" component="input"/>{' '}Categories Are Locked
                            </p>
                        </div>
                        <p>Changing categories when item variations exist will cause the details of each variation to be erased. Unlock and change categories with caution.</p>
                        <br/>
                    </>
                )}

                {optionStatus !== 'fetched' && <Loading/>}
                {optionStatus === 'fetched' && <>
                    <div className="columns is-multiline is-variable is-3">
                        <div className="column is-half">
                            <FormSection name="brand">
                                {this.renderBrand()}
                            </FormSection>
                        </div>
                        <div className="column is-half">
                            {this.showModels()}
                        </div>
                    </div>
                </>}
            </div>
        )
    }
}

export default CategorySelection;
import React from 'react'
import {Route, Switch} from "react-router-dom";
import ItemList from "./Item/List";
import V2EditItem from './Item/v2/Edit'
import {connect} from "react-redux";
import {Loading} from "./common/Loading";
import {checkAuthState} from "../store/actions/authenticationActions";

class AuthenticatedRoutes extends React.Component {

    componentDidMount() {
        const {checkAuthState} = this.props;

        checkAuthState();
    }

    render() {
        const {authStatus} = this.props;

        if(authStatus === 'uninitialized') {
            return <Loading/>
        }

        return (
            <Switch>
                <Route exact path="/" component={ItemList}/>
                <Route exact path="/item/:itemId" component={V2EditItem}/>
                {/*<Route path="/item/:itemId" component={EditItem}/>*/}
            </Switch>
        )
    }
}

const mapStateToProps = (state) => {

    return {authStatus: state.authenticatedUser.status}
};
const ConnectedAuthenticatedRoutes = connect(mapStateToProps, {checkAuthState})(AuthenticatedRoutes);
export default ConnectedAuthenticatedRoutes;

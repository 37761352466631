import { combineReducers } from 'redux';
import byId, * as fromById from './byId';
import userList, * as fromList from './list';
import isEmpty from 'lodash/isEmpty';

export default combineReducers({
    byId,
    userList: userList(),
});

export const getUsersForFilter = (state, ids = []) => {
    if (!ids.length) {
        ids = fromList.getIds(state.userList);
    }
    if (isEmpty(state.byId)) {
        return [];
    }

   return ids.map(id => fromById.getUser(state.byId, id));
};
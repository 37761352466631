import React from 'react';
import {discardDraft} from "../../../../store/actions/itemActions";
import {connect} from "react-redux";

class StateTransitionButton extends React.Component {

    render() {
        const {item, discardDraft} = this.props;

        if (item.state === 'draft') {
            return <button className="button is-warning is-medium" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) discardDraft(item)}}>Discard Draft</button>;
        } else {
            return null;
        }
    }
}

StateTransitionButton = connect(null, {discardDraft})(StateTransitionButton);
export default StateTransitionButton;
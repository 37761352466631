import React from 'react';
import {renderDropdownList} from "../../common/form/renderDropdownList";
import {Field, reduxForm} from "redux-form";
import {filterItems, removeFilterItem} from "../../../store/actions/filterActions";
import {connect} from "react-redux";
import {getCategories, getCategoryStatus} from "../../../store/reducer/category";
import {fetchCategories} from "../../../store/actions/categoryActions";
import {fetchUsersForFilter} from "../../../store/actions/userFilterActions";
import {getUsersForFilter} from "../../../store/reducer/userFilter";


class ItemFilter extends React.Component {
    componentDidMount() {
        this.props.fetchCategories();
    }

    renderTags = () => {
        const {categoryStatus, categories, filter, removeFilterItem} = this.props;

        if (categoryStatus !== 'fetched') {
            return null;
        }

        return (
            <div className="tags">
                {Object.entries(filter).map(filterItem => {
                    let key = filterItem[0];
                    let value = filterItem[1];
                    if (key === "category") {
                        value = categories.find(c => c.id === value).full_name;
                    }

                    // don't show the tag is ebay is not true
                    if (key === 'is_ebay' && value === false) {
                        return null;
                    }
                    return (
                        <span key={`filter-${key}`} className="tag is-medium is-info">
                            <strong>{key}:</strong>&nbsp;{value}
                            <button className="delete is-small" onClick={() => removeFilterItem(key)}></button>
                        </span>
                    )
                })}
            </div>
        )
    };

    renderSimpleDropdown = ({data, input, label, type, meta: {touched, error}}) => {
        let props = {
            input: input,
            data: data,
        };

        return (
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">{label}</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            {renderDropdownList(props)}
                            {touched && error && <p className="help is-danger">{error}</p>}
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    renderDropdown = ({data, input, label, type, valueField = "id", textField = "slug", meta: {touched, error}, filterType = 'startsWith', onSearch}) => {

        let props = {
            input: input,
            name: "category",
            textField,
            valueField,
            data: data,
            filterSelection: true,
            filterType,
            onSearch
        };

        return (
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">{label}</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            {renderDropdownList(props)}
                            {touched && error && <p className="help is-danger">{error}</p>}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderEbayItems = () => {
        return (
            <div className="field">
                <div className="control">
                    <label className="checkbox"><strong>Ebay Item</strong>&nbsp;&nbsp;
                        <Field name="is_ebay" type="checkbox" component="input"/>
                    </label>
                </div>
            </div>
        )
    };

    render() {
        const {handleSubmit, filterItems, categories, users = [], fetchUsersForFilter} = this.props;

        return (
            <>
                <form className="columns" onSubmit={handleSubmit(filterItems)}>
                    <div className="column is-1">
                        {this.renderEbayItems()}
                    </div>
                    <div className="column is-2">
                        <Field className="input" name="state" label="State" component={this.renderSimpleDropdown}
                               data={['available', 'draft']}/>
                    </div>

                    <div className="column is-4">
                        <Field name="category" label="Category" component={this.renderDropdown} data={categories} textField="full_name"
                               filterType="contains" normalize={v => v && v.id}/>
                    </div>

                    <div className="column is-4">
                        <Field name="seller" label="Users" component={this.renderDropdown} data={users}
                               onSearch={fetchUsersForFilter} textField="username" valueField="username"
                               normalize={v => v && v.username}
                        />
                    </div>

                    <div className="column">
                        <div className="field">
                            <button className="button is-link is-medium">Filter</button>
                        </div>
                    </div>


                </form>

                {this.renderTags()}
            </>
        )
    }
}

const ItemFilterForm = reduxForm({form: 'itemFilter', enableReinitialize: true})(ItemFilter);

const mapStateToProps = (state) => {
    const initialValues = state.filter;
    const filter = state.filter;
    const categories = getCategories(state.category);

    let categoryStatus = getCategoryStatus(state.category);
    const users = getUsersForFilter(state.userFilter);
    return {initialValues, categories, categoryStatus, users, filter}
};
const ConnectedItemFilterForm = connect(mapStateToProps, {
    filterItems,
    fetchCategories,
    fetchUsersForFilter,
    removeFilterItem
})(ItemFilterForm);
export default ConnectedItemFilterForm;
import React from 'react';

const stateTag = (state, tagColor) =>  <span className={`tag is-medium ${tagColor}`}>{state.toUpperCase()}</span>;

export default ({item}) => {
    switch(item.state) {
        case 'available':
            return stateTag(item.state, 'is-success');
        case 'draft':
            return stateTag(item.state, 'is-info');
        default:
            return stateTag(item.state, 'is-dark')
    }
}
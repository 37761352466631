import React from 'react';
import {reduxForm} from "redux-form";
import CategorySelection from "./_categories";
import ItemDetails from "./_details";
import GeneralInformation from "./_generalInformation";
import ShippingInformation from "./_shipping";
import ItemImages from "./_images";
import PrivateDetails from "./_privateDetails";
import FormActions from "./_formActions"
import ItemVariationSelection from "./variations/_variationSelection";

class EditItemForm extends React.Component {
    render() {
        const {item, categories, options, optionStatus, selectedCategory, variationState, category_locked_selector, handleSubmit} = this.props;

        return (
            <>
                <div className="columns">
                    <div className="column is-half">
                        <GeneralInformation item={item} variationState={variationState}/>
                        <ShippingInformation/>
                        <PrivateDetails item={item}/>
                        <ItemImages item={item}/>
                        <FormActions handleSubmit={handleSubmit}/>
                    </div>
                    <div className="column is-half">
                        <CategorySelection
                            item={item}
                            categories={categories}
                            options={options}
                            optionStatus={optionStatus}
                            selectedCategory={selectedCategory}
                            category_locked_selector={category_locked_selector}
                            variationState={variationState}
                           />
                        <ItemDetails
                            item={item}
                            options={options}
                            optionStatus={optionStatus}
                            variationState={variationState}
                        />
                        <ItemVariationSelection
                            item={item}
                            options={options}
                            variationState={variationState}
                            handleSubmit={handleSubmit}/>

                    </div>
                </div>

            </>
        )
    }
}

EditItemForm = reduxForm({form: 'updateItemV2', enableReinitialize: true})(EditItemForm);
export default EditItemForm
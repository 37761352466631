import { combineReducers } from 'redux';
import {
    FETCH_USERS_FOR_FILTER,
    SAVE_USERS_FOR_FILTER, USERS_FETCH_FOR_FILTER_FAILURE
} from '../../actions/userFilterActions';
import { produce } from 'immer';

const userList = () => {
    const ids = produce((draft, action) => {
        switch (action.type) {
            case SAVE_USERS_FOR_FILTER:
                action.userList.result.forEach(key => {
                    if (!draft.includes(key)) {
                        draft.push(key);
                    }
                });
                return;
            default:
                return draft;
        }
    }, []);

    const status = (state = null, action) => {
        switch (action.type) {
            case FETCH_USERS_FOR_FILTER:
                return "fetching";
            case SAVE_USERS_FOR_FILTER:
                return "fetched";
            default:
                return state;
        }
    };

    const errorMessage = (state = null, action) => {
        switch (action.type) {
            case USERS_FETCH_FOR_FILTER_FAILURE:
                return action.message;
            case FETCH_USERS_FOR_FILTER:
            case SAVE_USERS_FOR_FILTER:
                return null;
            default:
                return state;
        }
    };

    return combineReducers({
        ids,
        status,

        errorMessage,
    });
};

export default userList;

export const getIds = state => state.ids;

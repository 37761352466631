import {apiUrl} from "../../components/common/getEnv";

// Fetch urls
export const searchItemsUrl = (page=1, queryString) => `${apiUrl}/item_quality/items/search?sort=newest&page=${page}${queryString}`;
export const fetchItemUrl = (itemId) => `${apiUrl}/item_quality/items/${itemId}`;

// Update Item Urls
export const updateParcelUrl = () => `${apiUrl}/item_quality/parcels`;
export const updateItemUrl = (itemId) => `${apiUrl}/item_quality/items/${itemId}`;
export const validateItemUrl = (itemId) => `${apiUrl}/item_quality/items/${itemId}/validate`;
export const publishItemUrl = (itemId) => `${apiUrl}/item_quality/items/${itemId}/publish`;
export const discardDraftUrl = (itemId) => `${apiUrl}/item_quality/items/${itemId}/discard_draft`;

// item variation urls
export const createItemVariationUrl = (itemId, variationId) => `${apiUrl}/item_quality/items/${itemId}/variations`;
export const updateItemVariationUrl = (itemId, variationId) => `${apiUrl}/item_quality/items/${itemId}/variations/${variationId}`;
export const deleteItemVariationUrl = (itemId, variationId) => updateItemVariationUrl(itemId, variationId);

import {push} from 'connected-react-router';
import {
    DISCARD_DRAFT,
    FETCH_ITEM,
    FETCH_ITEMS,
    PUBLISH_ITEM,
    publishItem,
    REQUEST_ITEM_PUBLISH,
    REQUEST_ITEM_UPDATE,
    saveItem,
    saveItems,
    updateCurrentPage
} from "../actions/itemActions";
import {EMPTY, of} from "rxjs";
import {catchError, flatMap, map, switchMap} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {dispatchErrorsForForm} from "../../config/rootEpic";
import {sendFlashMessage} from "../actions/flashMessageActions";
import {FILTER_ITEMS, filterItems, REMOVE_FILTER_ITEM} from "../actions/filterActions";
import {buildQueryString} from "../reducer/filter";
import {refreshAuthTokens} from "../actions/authenticationActions";
import {
    discardDraftUrl,
    fetchItemUrl,
    publishItemUrl,
    searchItemsUrl,
    updateItemUrl,
    updateParcelUrl,
    validateItemUrl
} from "../urls/itemUrls";
import {scrollTop} from "../../helpers/commonHelper";
import {generateAuthHeaders} from "../../helpers/authHelper";
import {generateItemUpdatePayload, generateParcelPayload} from "../../helpers/itemHelper";

const fetchItems = (action$, state$) =>
    action$.ofType(FETCH_ITEMS).pipe(
        switchMap((action) => {
                const {page} = action;
                const filterQueryString = buildQueryString(state$.value.filter);

                // if the current page has not been fetched, go get it
                if (state$.value.pagination.pages[page] === undefined) {
                    return ajax.getJSON(searchItemsUrl(page, filterQueryString), generateAuthHeaders(state$)).pipe(
                        flatMap((resp) =>
                            [
                                saveItems(resp),
                                push(`/?page=${page}${filterQueryString}`)
                            ]
                        ),
                        catchError(err => {
                            console.log(err);
                            if (err.status === 403) {
                                return of(push("/login"))
                            }
                            if (err.status === 401) {
                                const refresh_token = state$.value.authentication.refresh_token;
                                return of(refreshAuthTokens(refresh_token, "/", action))
                            }

                            return EMPTY;
                        })
                    )
                } else {
                    // otherwise update current page
                    return of([1]).pipe(
                        flatMap(() => [
                            push(`/?page=${page}${filterQueryString}`),
                            updateCurrentPage(page)
                        ])
                    )
                }

            }
        )
    );

const fetchItem = (action$, state$) =>
    action$.ofType(FETCH_ITEM).pipe(
        switchMap(action => {
            return ajax.getJSON(fetchItemUrl(action.itemId), generateAuthHeaders(state$)).pipe(
                map(resp => saveItem(resp.data)),
                catchError(err => {
                    if (err.status === 403) {
                        return of(push("/login"))
                    }
                    if (err.status === 401) {
                        const refresh_token = state$.value.authentication.refresh_token;
                        return of(refreshAuthTokens(refresh_token, `/item/${action.itemId}`, action))
                    }
                })
            )
        })
    );

const handleItemFilter = (action$, state$) =>
    action$.ofType(FILTER_ITEMS).pipe(
        switchMap((action) => {
            const {data} = action;
            let qs = buildQueryString(data);

            // reset the page on a new filter search
            return ajax.getJSON(searchItemsUrl(1, qs), generateAuthHeaders(state$)).pipe(
                flatMap((resp) => {
                        return [
                            saveItems(resp),
                            push(`/?page=1${qs}`)
                        ]
                    }
                ),
                catchError(err => {
                    if (err.status === 401) {
                        const refresh_token = state$.value.authentication.refresh_token;
                        return of(refreshAuthTokens(refresh_token, "/", action))
                    }
                })
            )
        })
    );

const handleFilterChange = (action$, state$) =>
    action$.ofType(REMOVE_FILTER_ITEM).pipe(
        switchMap(() => of(filterItems(state$.value.filter)))
    );


const discardDraftItem = (action$, state$) =>
    action$.ofType(DISCARD_DRAFT).pipe(
        switchMap(({item}) => {
            return ajax.delete(discardDraftUrl(item.id), generateAuthHeaders(state$)).pipe(
                flatMap(resp => {
                    window.scrollTo(0, 0);

                    item.state = 'discarded';
                    return [
                        saveItem(item),
                        sendFlashMessage(`itemUpdated-${item.id}`, "Item has been discarded", 10000)]
                })
            )
        })
    );


// Item Updates


export const updateParcelObservable = (parcelPayload, headers) => {
    return ajax.post(updateParcelUrl(), parcelPayload, headers);
};

export const updateItemObservable = (itemId, itemUpdatePayload, headers) => {
    return ajax.put(updateItemUrl(itemId), itemUpdatePayload, headers);
};

const validateItemObservable = (itemId, headers) => {
    return ajax.post(validateItemUrl(itemId), null,  headers)
};

const handleFormError = (err, state$, data) => {
    if (err.status === 422) {
        window.scrollTo(0, 0);
        const categoryOptions = state$.value.categoryOptions.categories[data.category.id];
        return dispatchErrorsForForm(categoryOptions, err.response.errors, "updateItemV2")
    }
};


const publishItemObservable = (itemId, headers) =>
    ajax.post(publishItemUrl(itemId), null, headers).pipe(
        flatMap(resp => {
            scrollTop();
            return [
                saveItem(resp.response.data),
                sendFlashMessage(`itemUpdated-${itemId}`, "Item has been published", 10000)]
        }),
        catchError(err => {
            console.log(err);
            let message = err.response.errors[0].message;
            return of(sendFlashMessage(`itemUpdated-${itemId}`, message, 10000))
        })
    );

// Update parcel and item
const requestItemUpdate = (action$, state$) => {
    return action$.ofType(REQUEST_ITEM_UPDATE).pipe(
        switchMap(({data}) => {
            const headers = generateAuthHeaders(state$);
            const parcelPayload = generateParcelPayload(data);

            // First: Update Parcel
            return updateParcelObservable(parcelPayload, headers).pipe(
                flatMap((updateParcelResponse) => {
                    const parcelId = updateParcelResponse.response.data.id;
                    const itemUpdatePayload = generateItemUpdatePayload(data, parcelId);

                    // Second: Update Item
                    return updateItemObservable(data.id, itemUpdatePayload, headers).pipe(
                        flatMap((itemUpdateResponse) => {
                            scrollTop();
                            return [
                                saveItem(itemUpdateResponse.response.data),
                                sendFlashMessage(`itemUpdated-${data.id}`, 'The item has successfully been updated')
                            ]
                        }),
                        catchError(err => handleFormError(err, state$, data))
                    )
                }),
                catchError(err =>  {
                    return handleFormError(err, state$, data)
                })
            )
        })
    );
};

const requestItemPublish = (action$, state$) =>
    action$.ofType(REQUEST_ITEM_PUBLISH).pipe(
        switchMap(({data}) => {
            const headers = generateAuthHeaders(state$);
            const parcelPayload = generateParcelPayload(data);

            // First: Update Parcel
            return updateParcelObservable(parcelPayload, headers).pipe(
                flatMap((updateParcelResponse) => {
                    const parcelId = updateParcelResponse.response.data.id;
                    const itemUpdatePayload = generateItemUpdatePayload(data, parcelId);

                    // Second: Update Item
                    return updateItemObservable(data.id, itemUpdatePayload, headers).pipe(
                        map(() =>
                            // Third: Publish Item
                            publishItem(data)
                        ),
                        catchError(err => handleFormError(err, state$, data))
                    )
                }),
                catchError(err => handleFormError(err, state$, data))
            )
        })
    );

const submitItemPublish = (action$, state$) =>
    action$.ofType(PUBLISH_ITEM).pipe(
        switchMap(({data}) => {
            const itemId = data.id;
            let headers = generateAuthHeaders(state$);

            return validateItemObservable(itemId, headers).pipe(
                flatMap(() => {
                    return publishItemObservable(itemId, headers)
                }),
                catchError(err => handleFormError(err, state$, data))
            );
        })
    );



export const itemListEpics = [
    fetchItems,
    handleItemFilter,
    fetchItem,
    handleFilterChange,
    discardDraftItem,
    requestItemUpdate,
    requestItemPublish,
    submitItemPublish
];
import React from 'react';
import {Field, FormSection} from "redux-form";
import renderInput from "../../../../common/form/renderInput";

class ShippingInformation extends React.Component {
    render() {
        return (
            <div className="box">
                <h2 className="subtitle is-4">Shipping Details</h2>

                <FormSection name="parcel">
                    <div className="columns">
                        <div className="column is-one-third">
                            <Field name="weight_lbs" label="Weight" type="number" component={renderInput} addOnText="lbs"/>
                        </div>
                        <div className="column is-one-third">
                            <Field name="weight_oz" label="Ounces" type="number" component={renderInput} addOnText="oz"/>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column is-one-third">
                            <Field name="height" label="Height" type="number" component={renderInput} addOnText="in"/>
                        </div>
                        <div className="column is-one-third">
                            <Field name="width" label="Width" type="number" component={renderInput} addOnText="in"/>
                        </div>
                        <div className="column is-one-third">
                            <Field name="length" label="Length" type="number" component={renderInput} addOnText="in"/>
                        </div>
                    </div>
                </FormSection>

            </div>
        )
    }
}

export default ShippingInformation;
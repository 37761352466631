import { combineReducers } from 'redux';
import byId, * as fromById from './byId';
import categoryList, * as fromList from './list';
import isEmpty from 'lodash/isEmpty';

export default combineReducers({
    byId,
    categoryList: categoryList(),
});

export const getCategory = (state, id) => fromById.getCategory(state.byId, id);

export const getCategories = (state, ids = []) => {
    if (!ids.length) {
        ids = fromList.getIds(state.categoryList);
    }
    if (isEmpty(state.byId)) {
        return [];
    }

   return ids.map(id => fromById.getCategory(state.byId, id));
};

export const getCategoryStatus = state =>
    fromList.getStatus(state.categoryList);

export const getErrorMessage = state =>
    fromList.getErrorMessage(state.categoryList);

export const getDetailForCategory = (state, id, detail) =>
    fromById.getCategory(state.byId, id).details.data.filter(i => i.name === detail);
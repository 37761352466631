import {push} from 'connected-react-router';
import {change, formValueSelector} from "redux-form";
import {of} from "rxjs";
import {catchError, filter, flatMap, map, reduce, switchMap} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {FETCH_CATEGORIES, saveCategories} from "../actions/categoryActions";
import {generateAuthHeaders} from "../../helpers/authHelper";
import {fetchCategoryUrl} from "../urls/categoryUrls";
import {getCategory} from "../reducer/category";
import {getItem} from "../reducer/item";
import {updateVariationObservable} from "./v2/itemEpics";

const fetchCategories = (action$, state$) =>
    action$.ofType(FETCH_CATEGORIES).pipe(
        switchMap(() => {
                return ajax.getJSON(fetchCategoryUrl(), generateAuthHeaders(state$)).pipe(
                    flatMap((resp) =>
                        resp.data
                    ),
                    reduce((categories, category) => {
                        if(category.relatable) {
                            categories.push(category);
                        }

                        if(category.children) {
                            category.children.forEach(child => {
                                if (child.relatable) {
                                    categories.push(child);
                                }


                                if (child.children) {
                                    child.children.forEach(child2 => {
                                        if (child2.relatable) {
                                            categories.push(child2)
                                        }
                                    })
                                }
                            });
                        }

                        return categories
                    }, []),
                    map(categories =>
                        saveCategories(categories)
                    ),
                    catchError(err => {
                        console.log(err);

                        if (err.status === 401) {
                            return of(push("/login"))
                        }
                    })
                )
            }
        )
    );


const handleCategoryChange = (action$, state$) =>
    action$.ofType("@@redux-form/CHANGE").pipe(
        filter(action => {
            return action.meta.form === 'updateItemV2';
        }),
        filter(action => {
            return action.meta.field === 'category';
        }),
        filter(action => {
            let c = getCategory(state$.value.category, action.payload.id);
            const defaultParcel = c.default_parcel;

            return defaultParcel !== undefined

        }),
        flatMap(action => {
            let c = getCategory(state$.value.category, action.payload.id);
            const defaultParcel = c.default_parcel;
            return [
                change('updateItemV2', 'parcel.weight_lbs', defaultParcel.weight_lbs, false),
                change('updateItemV2', 'parcel.weight_oz', defaultParcel.weight_oz, false),
                change('updateItemV2', 'parcel.height', defaultParcel.height, false),
                change('updateItemV2', 'parcel.width', defaultParcel.width, false),
                change('updateItemV2', 'parcel.length', defaultParcel.length, false),
            ];
        })
    );

const clearVariationDetailsOnCategoryChange =  (action$, state$) =>
    action$.ofType("@@redux-form/CHANGE").pipe(
        filter(action => {
            return action.meta.form === 'updateItemV2';
        }),
        filter(action => {
            return action.meta.field === 'category';
        }),
        filter(action => {
            // does item have variations

            const selector = formValueSelector('updateItemV2');
            const itemId = selector(state$.value, 'id');

            const item = getItem(state$.value.item, itemId);
            return item.variations.length > 0
        }),
        flatMap(action => {
            const selector = formValueSelector('updateItemV2');
            const itemId = selector(state$.value, 'id');

            const item = getItem(state$.value.item, itemId);

            let variationList = [];

            item.variations.forEach(v => {
                variationList.push( {
                    itemId: item.id,
                    variation: v
                })
            });

            return variationList
        }),
        flatMap(v => {
            v.details = [];

            return updateVariationObservable(v.itemId, v.variation, state$);
        })
    );

export const categoryEpics = [
    fetchCategories,
    handleCategoryChange,
    clearVariationDetailsOnCategoryChange
];
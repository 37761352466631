import React from 'react';
import {Field, FormSection} from "redux-form";
import renderInput from "../../../../../common/form/renderInput";
import {renderDropdown} from "../../../../../common/form/renderDropdownList";
import {connect} from "react-redux";
import {createVariation, deleteVariation, updateVariation} from "../../../../../../store/actions/v2/itemActions";

class Variation extends React.Component {
    render() {
        const {item, variation, counter, variationDetails, createVariation, updateVariation, deleteVariation, handleSubmit} = this.props;

        return (
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">Variation {counter}</p>
                    <button className="card-header-icon button is-text" onClick={() => {
                        if (window.confirm('Are you sure you wish to delete this item?')) deleteVariation(`variation-${counter}`, variation.id, item.id)
                    }}>
                        <span className="icon"><i className="fa fa-trash"/></span>
                    </button>
                </header>

                <div className="card-content">
                    <FormSection name={`variation-${counter}`}>
                        <Field name="id" component="input" type="hidden"/>
                        <div className="columns">
                            <div className="column is-half"><Field name="name" label="Name" component={renderInput}/></div>
                            <div className="column is-half"><Field type="number" name="quantity" label="Quantity" component={renderInput}/></div>
                        </div>

                        <div className="columns is-multiline">
                            {variationDetails.map(detail => {
                                let fieldName = detail.required ? `${detail.name} (Required)` : detail.name;

                                return (
                                    <div key={`dtl-${detail.slug}-${variation.id}`} className="column is-one-third">
                                        <Field name={detail.slug} label={fieldName} component={renderDropdown} valueField={"slug"}
                                               data={detail.children} normalize={v => v && v.slug}/>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="field is-grouped">
                            {typeof(variation.id) === "string" && (
                            <p className="control">
                                <button className="button is-primary" onClick={values => handleSubmit(createVariation(`variation-${counter}`, item.id))}>Create</button>
                            </p>
                            )}
                            {typeof(variation.id) === "number" && (
                            <p className="control">
                                <button className="button is-primary" onClick={values => handleSubmit(updateVariation(`variation-${counter}`, item.id))}>Update</button>
                            </p>
                            )}
                            <p className="control">
                                <button className="button is-text">Cancel</button>
                            </p>
                        </div>
                    </FormSection>
                </div>
            </div>
        )
    }
}

const ConnectedVariation = connect(null, {createVariation, updateVariation, deleteVariation})(Variation);

export default ConnectedVariation;
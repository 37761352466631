import {ADD_FLASH_MESSAGE, CLEAR_ALL_MESSAGES, REMOVE_FLASH_MESSAGE} from "../../actions/flashMessageActions";

const flashMessage = (state = {}, action) => {
    switch (action.type) {
        case ADD_FLASH_MESSAGE:
            const {message} = action;
            return {...state, ...message};
        case REMOVE_FLASH_MESSAGE:
            const {key} = action;
            let res = Object.assign({}, state);

            delete res[key];
            return res;
        case CLEAR_ALL_MESSAGES:
            return {};
        default:
            return state;
    }
};

export default flashMessage;
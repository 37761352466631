import { combineReducers } from 'redux';
import {
    FETCH_CATEGORIES,
    CATEGORIES_FETCH_FAILURE,
    SAVE_CATEGORIES,
} from '../../actions/categoryActions';
import { produce } from 'immer';

const categoryList = () => {
    const ids = produce((draft, action) => {
        switch (action.type) {
            case SAVE_CATEGORIES:
                action.response.result.forEach(key => {
                    if (!draft.includes(key)) {
                        draft.push(key);
                    }
                });
                return;
            default:
                return draft;
        }
    }, []);

    const status = (state = null, action) => {
        switch (action.type) {
            case FETCH_CATEGORIES:
                return "fetching";
            case SAVE_CATEGORIES:
            case CATEGORIES_FETCH_FAILURE:
                return "fetched";
            default:
                return state;
        }
    };

    const errorMessage = (state = null, action) => {
        switch (action.type) {
            case CATEGORIES_FETCH_FAILURE:
                return action.message;
            case FETCH_CATEGORIES:
            case SAVE_CATEGORIES:
                return null;
            default:
                return state;
        }
    };

    return combineReducers({
        ids,
        status,

        errorMessage,
    });
};

export default categoryList;

export const getIds = state => state.ids;
export const getStatus = state => state.status;
export const getErrorMessage = state => state.errorMessage;

import {push} from 'connected-react-router';
import {EMPTY, of} from "rxjs";
import {catchError, filter, flatMap, map, switchMap} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {FETCH_CATEGORY_DETAILS} from "../actions/categoryActions";
import {change} from "redux-form";
import {addDetailsToCategory} from "../actions/categoryOptionActions";
import {generateAuthHeaders} from "../../helpers/authHelper";
import {fetchDetailUrl} from "../urls/detailUrls";

export const REDUX_FORM_CHANGE = "@@redux-form/CHANGE";

const fetchDetails = (action$, state$) =>
    action$.ofType(FETCH_CATEGORY_DETAILS).pipe(
        switchMap((action) => {
                return ajax.getJSON(fetchDetailUrl(action.categoryId), generateAuthHeaders(state$)).pipe(
                    map((resp) =>
                        addDetailsToCategory(action.categoryId, resp.data)
                    ),
                    catchError(err => {
                        if (err.status === 401) {
                            return of(push("/login"))
                        }
                    })
                )
            }
        )
    );

const fetchDetailsOnCategoryChange = (action$, state$) =>
    action$.ofType(REDUX_FORM_CHANGE).pipe(
        filter(action => {
            return action.meta.form === 'updateItemV2';
        }),
        filter(action => {
            return action.meta.field === 'category';
        }),
        filter(action => {
            const newCategoryId = action.payload.id;
            return state$.value.categoryOptions[newCategoryId] === undefined;
        }),
        switchMap((action) => {
                const categoryId = action.payload.id;

                return ajax.getJSON(fetchDetailUrl(categoryId), generateAuthHeaders(state$)).pipe(
                    map((resp) =>
                        addDetailsToCategory(categoryId, resp.data)
                    ),

                    catchError(err => {
                        if (err.status === 401) {
                            return of(push("/login"))
                        }
                    })
                )
            }
        )
    );

const handleCategoryChange = (action$, state$) =>
    action$.ofType(REDUX_FORM_CHANGE).pipe(
        filter(action => {
            return action.meta.form === 'updateItemV2';
        }),
        filter(action => {
            return action.meta.field === 'category';
        }),
        flatMap(() => {
            return [
                change("updateItemV2", "brand", "", false),
                change("updateItemV2", "details", "", false),
            ];
        })
    );

export const detailEpics = [
    fetchDetails,
    fetchDetailsOnCategoryChange,
    handleCategoryChange
];
import React from 'react';

class ItemImages extends React.Component {
    render() {
        const {item} = this.props;

        return (
            <div className="box">
                <h2 className="subtitle is-4">Images</h2>
                {item.images.length === 0 && <p>This item has no images.</p>}
                {item.images.length > 0 && (<div className="columns is-multiline">
                    {item.images.map(image => (
                        <div key={`it-img-${image.id}`} className="column is-one-quarter">
                            <div className="image is-square item-image">
                                <img src={image.small_url} alt=""/>
                            </div>
                        </div>
                    ))}
                </div>)
                }
            </div>
        )
    }
}

export default ItemImages;
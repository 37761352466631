import {FILTER_ITEMS, REMOVE_FILTER_ITEM, SET_INITIAL_FILTER} from "../../actions/filterActions";
import {isObjectEmpty} from "../../../utils/objectUtils";

const initialState = {
    state: "draft"
};


const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INITIAL_FILTER:
            if (isObjectEmpty(action.data)) {
                return initialState
            } else {
                return action.data;
            }
        case FILTER_ITEMS:
            return action.data;
        case REMOVE_FILTER_ITEM:
            return Object.keys(state).reduce((object, key) => {
                if (key !== action.filterItem) {
                    object[key] = state[key]
                }
                return object
            }, {});
        default:
            return state;
    }
};


export default filterReducer;

export const convertParamsToFilter = (urlParams) => {
    let filter = {};

    if (urlParams.get("seller[]")) {
        filter.seller = urlParams.get("seller[]");
    }

    if (urlParams.get("state[]")) {
        filter.state = urlParams.get("state[]")
    }

    if (urlParams.get("category[]")) {
        filter.category = parseInt(urlParams.get("category[]"));
    }

    return filter;
};

export const buildQueryString = (filter) => {

    let queryString = "";
    try {
        if (filter.state) {
            queryString += `&state[]=${filter.state}`
        }

        if (filter.category) {
            queryString += `&category[]=${filter.category}`
        }

        if (filter.seller) {
            queryString += `&seller[]=${filter.seller}`
        }

        if (filter.is_ebay) {
            queryString += '&is_ebay=true'
        }

    } catch (e) {

    }
    return queryString;
};
import {schema} from 'normalizr';

export const category = new schema.Entity('category');
export const arrayOfCategories = new schema.Array(category);

export const item = new schema.Entity('item');
export const arrayOfItems = new schema.Array(item);

export const detail = new schema.Entity('detail');
export const arrayOfDetails = new schema.Array(detail);

export const user = new schema.Entity('user');
export const arrayOfUsers = new schema.Array(user);
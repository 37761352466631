import { produce } from 'immer';
import {
    SAVE_CATEGORY,
    SAVE_CATEGORIES, SAVE_CATEGORY_DETAILS,

} from '../../actions/categoryActions';
import forOwn from 'lodash/forOwn';

const byId = produce((draft, action) => {
    switch (action.type) {
        case SAVE_CATEGORIES:
        case SAVE_CATEGORY:
            forOwn(action.response.entities.category, (value, key) => {
                draft[key] = value;
            });
            return;
        case SAVE_CATEGORY_DETAILS:
            draft[action.categoryId].details = {
                state: 'fetched',
                data: action.details
            };
            return;

        default:
            return draft;
    }
}, {});

export default byId;

export const getCategory = (state, id) => state[id];

import {addDays} from 'date-fns';

export const LOGOUT = "LOGOUT";
export const INITIALIZE_AUTHENTICATION = "INITIALIZE_AUTHENTICATION";

export const ATTEMPT_AUTHENTICATION = "ATTEMPT_AUTHENTICATION";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";
export const AUTHENTICATION_SUCCEEDED = "AUTHENTICATION_SUCCEEDED";
export const SET_RECAPTCHA_TOKEN = "SET_RECAPTCHA_TOKEN"
export const TOKEN_REFRESH = "TOKEN_REFRESH";


export const setInitialTokenState = (access_token, refresh_token) => ({
    type: INITIALIZE_AUTHENTICATION,
    access_token,
    refresh_token
});

export const refreshAuthTokens = (refresh_token, returnPath, retriggerActionOnSuccess) => ({
    type: TOKEN_REFRESH,
    refresh_token,
    returnPath,
    retriggerActionOnSuccess
});

export const attemptAuthentication = ({username, password}) => ({
    type: ATTEMPT_AUTHENTICATION,
    username,
    password
});

export const authenticationFailed = () => ({
    type: AUTHENTICATION_FAILED,
    message: "Invalid username or password"
});


export const authenticationSucceeded = (data, redirectPath = "/") => ({
    type: AUTHENTICATION_SUCCEEDED,
    data,
    redirectPath
});

export const logout = () => ({
    type: LOGOUT
});

export const setRecaptchaToken = (token) => ({
    type: SET_RECAPTCHA_TOKEN,
    data: {
        token: token
    }
})


// V2 Auth

export const CHECK_AUTH_STATE = "CHECK_AUTH_STATE";
export const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";

export const checkAuthState = () => ({
    type: CHECK_AUTH_STATE
});

export const checkAuthSuccess = () => ({
    type: CHECK_AUTH_SUCCESS
});


// todo: Find better place for this

export const setTokensInCookie = (access_token, refresh_token) => {
    const authExpiry = addDays(new Date(), 90).toUTCString();


    if (access_token) {
        // Use "auth_token" still to create a distinction
        // document.cookie = `auth_token=${access_token}; expires=${authExpiry}; path=/`;
        document.cookie = `access_token=${access_token}; expires=${authExpiry}; path=/`;
    }

    if (refresh_token && refresh_token !== 'undefined') {
        document.cookie = `refresh_token=${refresh_token}; expires=${authExpiry}; path=/`;
    }
};

export const removeCookies = () => {
    document.cookie =  'access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =  'refresh_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
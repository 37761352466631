import React from 'react';
import {fetchItems} from "../../../store/actions/itemActions";
import {connect} from "react-redux";
import {getItems, getItemStatus} from "../../../store/reducer/item";
import ItemTable from "./ItemTable";
import {Loading} from "../../common/Loading";
import ItemFilter from "./ItemFilter";
import {fetchCategories} from "../../../store/actions/categoryActions";

class ItemList extends React.Component {
    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        let page = params.get("page") || 1;
        this.props.fetchItems(page);
    }

    pagingMeta = () => {
        const {items = [], itemStatus,  pagination} = this.props;

        if(pagination.page ===  0 || itemStatus === 'fetching') {
            return;
        }

        if(items.length === 0) {
            return <span>No Results</span>
        }

        let start = pagination.page_size * (pagination.page - 1) + 1;

        let end = start + pagination.page_size - 1;
        if(end > pagination.total_count) {
            end = pagination.total_count;
        }

        return <span>Showing {start} - {end} of {pagination.total_count}</span>
    };

    pagingButtons = () => {
        const {items = [], pagination: {page, total_pages}, fetchItems} = this.props;
        if(page === 0 || items.length === 0) {
            return;
        }

        let prev = <button className="button is-light" onClick={() => fetchItems(page - 1)}>Previous</button>;

        if(page === 1) {
            prev = <button className="button is-light" disabled={true}>Previous</button>
        }

        let next = <button  className="button is-light" onClick={() => fetchItems(page + 1)}>Next</button>;
        if(total_pages === page) {
            next = <button className="button is-light" disabled={true}>Next</button>
        }

        return (
            <div className="field is-grouped is-grouped-right">
                <p className="control">{prev}</p>
                <p className="control">{next}</p>
            </div>
        )

    };

    render() {
        const {itemStatus, items = []} = this.props;

        return (
            <div>
                <h1 className="title">Item List</h1>
                <ItemFilter />
                <div className="box">
                    <div className="columns">
                        <div className="column is-half">
                            {this.pagingMeta()}
                        </div>
                        <div className="column is-half">
                            {this.pagingButtons()}
                        </div>
                    </div>
                    {itemStatus === 'fetched' && <ItemTable items={items}/>}
                    {itemStatus !== 'fetched' && <Loading/>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let items = [];
    let itemsInCurrentPage = state.pagination.pages[state.pagination.page];
    if(itemsInCurrentPage && itemsInCurrentPage.length > 0) {
        items = getItems(state.item, itemsInCurrentPage);
    }

    let itemStatus = getItemStatus(state.item);
    const pagination = state.pagination;
    const filter = state.filter;
    return {items, itemStatus, pagination, filter}
};
const ConnectedItemList = connect(mapStateToProps, {fetchItems, fetchCategories})(ItemList);
export default ConnectedItemList;
import { produce } from 'immer';
import {
    SAVE_ITEM,
    SAVE_ITEMS,

} from '../../actions/itemActions';
import forOwn from 'lodash/forOwn';

const byId = produce((draft, action) => {
    switch (action.type) {
        case SAVE_ITEMS:
        case SAVE_ITEM:
            forOwn(action.itemList.entities.item, (value, key) => {
                draft[key] = value;
            });
            return;
        default:
            return draft;
    }
}, {});

export default byId;

export const getItem = (state, id) => state[id];

import * as schema from '../../config/schema';
import {normalize} from 'normalizr';

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const SAVE_CATEGORIES = "SAVE_CATEGORIES";
export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const CATEGORIES_FETCH_FAILURE = "CATEGORIES_FETCH_FAILURE";

export const FETCH_CATEGORY_DETAILS = "FETCH_CATEGORY_DETAILS";
export const SAVE_CATEGORY_DETAILS = "SAVE_CATEGORY_DETAILS";

export const CREATE_CATEGORY = "CREATE_CATEGORY";


export const fetchCategory = (categoryId) => ({
    type: FETCH_CATEGORY,
    categoryId
});

export const saveCategories = (categories) => ({
    type: SAVE_CATEGORIES,
    response: normalize(categories, schema.arrayOfCategories)
});

export const saveCategory = (category) => ({
    type: SAVE_CATEGORY,
    response: normalize(category, schema.category)
});

export const createCategory = (data) => ({
    type: CREATE_CATEGORY,
    data
});

export const fetchCategories = () => ({
    type: FETCH_CATEGORIES
});

export const fetchCategoryDetails = (categoryId) => ({
    type: FETCH_CATEGORY_DETAILS,
    categoryId
});

export const saveCategoryDetails = (categoryId, details) => ({
    type: SAVE_CATEGORY_DETAILS,
    categoryId,
    details
});
import React from 'react';
import Variation from "./variation";
import {getItem} from "../../../../../../store/reducer/item";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {addVariation} from "../../../../../../store/actions/v2/itemActions";
import {formValueSelector} from "redux-form";

class VariationList extends React.Component {
    render() {
        const {item, variationState: {detailVariations = []}, variations, options: {details = []}, selectedVariations, handleSubmit, addVariation, flashMessage} = this.props;

        const variationDetails = details.filter(d => detailVariations.includes(d.slug));

        return (
            <div>
                {flashMessage[`variationUpdated`] && (
                    <div className="message is-success">
                        <div className="message-body">
                            {flashMessage[`variationUpdated`]}
                        </div>
                    </div>
                )}
                {variations.map((variation, index)=> {
                    return <Variation key={`var-${variation.id}`} item={item} variation={variation} counter={index+1}  variationDetails={variationDetails} handleSubmit={handleSubmit}/>
                })}

                {selectedVariations === 'variation' && <button className="button is-primary" onClick={() => addVariation(item.id)}>Add New Variation</button>}
            </div>
        )
    }
}


const mapStateToProps = (state, props) => {
    const item = getItem(state.item, props.match.params.itemId);
    const selector = formValueSelector('updateItemV2');
    const selectedVariations = selector(state, 'variationSelection');
    const flashMessage = state.flashMessage;
    return {
        item,
        variations: item.variations,
        selectedVariations,
        flashMessage
    }
};
export default withRouter(connect(mapStateToProps, {addVariation})(VariationList));
import React from 'react';

const renderInput = ({input, label, placeholder = "", autoComplete = "", type, meta: {touched, error}, tabIndex = 0, elementClassName = "field", addOnText = false}) => (
        <div className={elementClassName}>
            <label className="label">{label}</label>
            <div className={`${elementClassName} ${addOnText ? 'has-addons' : ''}`}>
                <p className="control">
                    <input {...input} placeholder={placeholder || label} type={type} className="input" tabIndex={tabIndex}
                           autoComplete={autoComplete}/>
                </p>
                {addOnText && (
                    <p className="control">
                        <span className="button is-static">
                            {addOnText}
                        </span>
                    </p>
                )}
                {touched && error && <span className="help is-danger">{error}</span>}
            </div>
        </div>
    )
;

export default renderInput
export const FILTER_ITEMS = "FILTER_ITEMS";
export const SET_INITIAL_FILTER = "SET_INITIAL_FILTER";
export const REMOVE_FILTER_ITEM = "REMOVE_FILTER_ITEM";

export const filterItems = (data) => ({
    type: FILTER_ITEMS,
    data
});

export const setInitialFilter = (data) => ({
    type: SET_INITIAL_FILTER,
    data
});


export const removeFilterItem = (filterItem) => ({
    type: REMOVE_FILTER_ITEM,
    filterItem
});
export const ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE";
export const REMOVE_FLASH_MESSAGE = "REMOVE_FLASH_MESSAGE";
export const CLEAR_ALL_MESSAGES = "CLEAR_ALL_MESSAGES";


export const sendFlashMessage = (key, text, delay = 5000) => {
    let msg = {};
    msg[key] = text;


    return {
        type: ADD_FLASH_MESSAGE,
        key,
        message: msg,
        delay
    }
};

export const removeFlashMessage = (key) => {
    return {
        type: REMOVE_FLASH_MESSAGE, key
    }
};

export const clearAllFlashMessages = () => ({
    type: CLEAR_ALL_MESSAGES
});
import React from 'react';
import {Link} from "react-router-dom";

export const Pagination = ({prevItem, nextItem}) => (
    <div className="field is-grouped is-grouped-right">
        <p className="control">
            {prevItem ? button(prevItem.id, "Previous Item", false) : button(null, "Previous Item", true)}
        </p>
        <p className="control">
            {nextItem ? button(nextItem.id, "Next Item", false) : button(null, "Next Item", true)}
        </p>
    </div>
);


const button = (id, label, disabled = false) => {
    return disabled ?
        <button className="button is-disabled" disabled={true}>{label}</button>
        : <Link className="button" to={`/item/${id}`}>{label}</Link>
};
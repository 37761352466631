import {
    AUTHENTICATION_FAILED,
    AUTHENTICATION_SUCCEEDED,
    INITIALIZE_AUTHENTICATION, SET_RECAPTCHA_TOKEN
} from "../../actions/authenticationActions";

const initialState = {
    message: null,
    recaptchaToken: null,
    access_token: null,
    refresh_token: null
};

export const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_AUTHENTICATION:
            return {access_token: action.access_token, refresh_token: action.refresh_token};
        case AUTHENTICATION_SUCCEEDED:
            return {message: null, ...action.data};
        case SET_RECAPTCHA_TOKEN:
            return {recaptchaToken: action.data.token};
        case AUTHENTICATION_FAILED:
            return {message:  action.message};
        default:
            return state
    }
};

export const getaccess_token = (store) => {
    return store.access_token;
};
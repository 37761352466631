import {UPDATE_USER} from "../../actions/loggedInUserActions";
import {
    AUTHENTICATION_SUCCEEDED,
    CHECK_AUTH_SUCCESS,
    INITIALIZE_AUTHENTICATION,
    LOGOUT
} from "../../actions/authenticationActions";
import {combineReducers} from "redux";
import jwt_decode from "jwt-decode";

const initialState = {};

export const authenticatedUserReducer = () => {
    const user = (state = initialState, action) => {

        switch (action.type) {
            case INITIALIZE_AUTHENTICATION:
                var decoded = jwt_decode(action.access_token);
                return {username: decoded.sub};
            case UPDATE_USER:
                return {...action.data};
            case LOGOUT:
                return {};
            default:
                return state;
        }
    };

    const status = (state = "uninitialized", action) => {
        switch (action.type) {
            case AUTHENTICATION_SUCCEEDED:
            case CHECK_AUTH_SUCCESS:
                return 'success';
            default:
                return state;

        }
    };


    return combineReducers({user: user, status});

};
import React from 'react';
import {Field, FormSection} from "redux-form";
import renderInput from "../../../../common/form/renderInput";

class PrivateDetails extends React.Component {
    showForm = () => {
        const {item: {seller: {gtin, mpn, inventory, my_cost}}} = this.props;

        return (gtin || mpn || inventory || my_cost)
    };

    render() {
        const {item: {seller: {gtin, mpn, inventory, my_cost}}} = this.props;

        if (!this.showForm()) {
            return null;
        }

        return (
            <div className="box">
                <h2 className="subtitle is-4">Private Details</h2>

                <FormSection name="privateDetails">
                    <div className="columns is-multiline is-variable is-3">
                        {my_cost && (
                            <div className="column is-3">
                                <Field name="cost" label="My Cost" type="text" component={renderInput} addOnText="USD"/>
                            </div>
                        )}
                        {gtin && (
                            <div className="column is-3">
                                <Field name="gtin" label="GTIN (UPC)" type="text" component={renderInput}/>
                            </div>
                        )}
                        {mpn && (
                            <div className="column is-3">
                                <Field name="mpn" label="MPN" type="text" component={renderInput}/>
                            </div>
                        )}
                        {inventory && (
                            <div className="column is-3">
                                <Field name="inventory_id" label="Inventory ID" type="text" component={renderInput}/>
                            </div>
                        )}
                    </div>
                </FormSection>
            </div>
        )
    }
}

export default PrivateDetails;
import * as schema from '../../config/schema';
import {normalize} from 'normalizr';

export const FETCH_ITEMS = "FETCH_ITEMS";
export const FETCH_ITEM = "FETCH_ITEM";
export const SAVE_ITEMS = "SAVE_ITEMS";
export const SAVE_ITEM = "SAVE_ITEM";
export const ITEMS_FETCH_FAILURE = "ITEMS_FETCH_FAILURE";


export const UPDATE_ITEM = "UPDATE_ITEM";
export const PUBLISH_ITEM = "PUBLISH_ITEM";
export const DISCARD_DRAFT = "DISCARD_DRAFT";
export const UPDATE_AND_PUBLISH_ITEM = "UPDATE_AND_PUBLISH_ITEM";
export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE";

export const fetchItem = (itemId) => ({
    type: FETCH_ITEM,
    itemId
});

export const saveItems = (items) => ({
    type: SAVE_ITEMS,
    itemList: normalize(items.data, schema.arrayOfItems),
    paging: items.meta.paging,
    query: items.meta.query
});

export const saveItem = (item) => ({
    type: SAVE_ITEM,
    itemList: normalize(item, schema.item)
});



export const fetchItems = (page) => ({
    type: FETCH_ITEMS,
    page
});

export const updateItem = (data) => ({
    type: UPDATE_ITEM,
    data
});

export const updateAndPublishItem = (data) => ({
    type: UPDATE_AND_PUBLISH_ITEM,
    data
});

export const publishItem = (data) => ({
    type: PUBLISH_ITEM,
    data
});

export const updateCurrentPage = (page) => ({
    type: UPDATE_CURRENT_PAGE,
    page
});

export const discardDraft = (item) => ({
    type: DISCARD_DRAFT,
    item
});


// ITEM UPDATE

export const REQUEST_ITEM_UPDATE = "REQUEST_ITEM_UPDATE";
export const REQUEST_ITEM_PUBLISH = "REQUEST_ITEM_PUBLISH";

export const requestItemUpdate = (data) => ({
    type: REQUEST_ITEM_UPDATE,
    data
});

export const requestItemPublish = (data) => ({
    type: REQUEST_ITEM_PUBLISH,
    data
});
import React from 'react';
import {Field} from "redux-form";
import VariationList from "./variationList";

class ItemVariationSelection extends React.Component {
    variationControlLocked = () => {
        const { variationState: {itemHasVariations, can_have_variations}}   = this.props;

        return itemHasVariations && can_have_variations;
    };

    render() {
        const { variationState, options, handleSubmit} = this.props;

        // Return if no details can have variations
        if (!variationState.can_have_variations) {
            return null;
        }

        return (
            <div className="box">
                <h2 className="subtitle is-4">Variations</h2>
                {this.variationControlLocked() && <p className="help is-info has-bottom-spacing">This control is disabled while there are variations.</p>}
                <div className="columns control">
                    <div className="column is-one-third">
                        <label className="radio">
                            <Field name="variationSelection" component="input" type="radio" value="single" disabled={this.variationControlLocked()}/>{' '}
                            This is a single item
                        </label>
                    </div>
                    <div className="column">
                        <label className="radio">
                            <Field name="variationSelection" component="input" type="radio" value="variation" disabled={this.variationControlLocked()} />{' '}
                            This item has variations
                        </label>
                    </div>
                </div>


                <VariationList variationState={variationState} options={options} handleSubmit={handleSubmit}/>
            </div>
        )
    }
}

export default ItemVariationSelection;
import {ATTEMPT_AUTHENTICATION, authenticationFailed, authenticationSucceeded, CHECK_AUTH_STATE, checkAuthSuccess, LOGOUT, refreshAuthTokens, removeCookies, TOKEN_REFRESH} from "../actions/authenticationActions";
import {push} from 'connected-react-router';
import {catchError, flatMap, map, switchMap} from "rxjs/operators";

import {of} from "rxjs";
import {ajax} from "rxjs/ajax";
import {dispatchErrorsForForm} from "../../config/rootEpic";
import {generateAuthHeaders} from "../../helpers/authHelper";
import {checkSessionUrl, loginUrl, refreshTokenUrl} from "../urls/authUrls";


const postLoginLocation = (state$) => {
    if (state$.value.router.location.pathname === "/login") {
        return "/"
    }
    return state$.value.router.location.pathname;
};

const refreshTokens = (action$, state$) =>
    action$.ofType(TOKEN_REFRESH).pipe(
        // throttle(action => interval(100)),
        switchMap((action) => {
            let {refresh_token, returnPath} = action;

            if (!returnPath) {
                returnPath = postLoginLocation(state$)
            }
            const body = {
                "grant_type": "refresh_token",
                "token": refresh_token,
                "client_id": "1"
            };

            // if an API call receives a 401, we should retry that action after we've refreshed the tokens
            return ajax.post(refreshTokenUrl(), body, {'Content-Type': 'application/json'}).pipe(
                flatMap(results =>
                    [
                        authenticationSucceeded(results.response.data, returnPath),
                        action.retriggerActionOnSuccess || {type: 'NOTHING_TO_DO'}
                    ]
                ),
                catchError(err => {

                    if (err.status === 401 || err.status === 400) {
                        // remove tokens and go to login
                        // todo: call LOGOUT instead
                        removeCookies();
                        return of(push('/login'))
                    }
                }))
        })
    );


const handleAuthenticationAttempt = (action$, state$) =>
    action$.ofType(ATTEMPT_AUTHENTICATION).pipe(
        switchMap(action => {
            const body = {
                "grant_type": "client_credentials",
                "username_or_email": action.username,
                "password": action.password,
            };

            const recaptchaToken = state$.value.authentication.recaptchaToken;
            console.log(recaptchaToken);
            return ajax.post(loginUrl(), body, {'X-SLS-Client-Type': 'WEB', 'g-recaptcha-token': recaptchaToken, 'Content-Type': 'application/json'}).pipe(
                flatMap(results => {
                    return [
                        authenticationSucceeded(results.response.data, postLoginLocation(state$)),
                    ]
                }),
                catchError(err => {
                    console.log(err);
                    if (err.status === 422) {
                        return of(authenticationFailed())
                    }
                    if (err.status === 401) {

                    }
                    return dispatchErrorsForForm(err.response.errors, 'loginForm');
                })
            )
        })
    );

// v2 auth

const checkAuthState = (action$, state$) =>
    action$.ofType(CHECK_AUTH_STATE).pipe(
        switchMap(action => {
            return ajax.getJSON(checkSessionUrl(), generateAuthHeaders(state$)).pipe(
                map(() => {
                    return checkAuthSuccess()
                }),
                catchError(err => {
                    if (err.status === 401) {
                        const refresh_token = state$.value.authentication.refresh_token;
                        return of(refreshAuthTokens(refresh_token, postLoginLocation(state$)))
                    }
                })
            )

        })
    );

const logout = (action$) =>
    action$.ofType(LOGOUT).pipe(
        switchMap(action => {
            removeCookies();
            return of(push("/login"))
        })
    );

export const authenticationEpics = [
    handleAuthenticationAttempt,
    refreshTokens,
    logout,
    checkAuthState
];
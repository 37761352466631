export const V2_FETCH_ITEM = "V2_FETCH_ITEM";
export const V2_LOAD_ITEM_DETAILS = "V2_LOAD_ITEM_DETAILS";

export const ADD_VARIATION = "ADD_VARIATION";
export const CREATE_VARIATION = "CREATE_VARIATION";
export const UPDATE_VARIATION = "UPDATE_VARIATION";
export const DELETE_VARIATION = "DELETE_VARIATION";

export const fetchItem = (itemId) => ({
    type: V2_FETCH_ITEM,
    itemId
});

export const loadItemDetails = (item) => ({
    type: V2_LOAD_ITEM_DETAILS,
    item
});

export const createVariation = (variationIndex, itemId) => ({
    type: CREATE_VARIATION,
    variationIndex,
    itemId
});
export const updateVariation = (variationIndex, itemId) => ({
    type: UPDATE_VARIATION,
    variationIndex,
    itemId
});
export const deleteVariation = (variationIndex, variationId, itemId) => ({
    type: DELETE_VARIATION,
    variationIndex,
    variationId,
    itemId
});

export const addVariation = (itemId) => ({
    type: ADD_VARIATION,
    itemId
});
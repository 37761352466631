import React from 'react'
import {fetchItem} from "../../../../store/actions/v2/itemActions";
import ItemStatus from './_itemStatus'
import {connect} from "react-redux";
import {getItem, getItemStatus, getNextItem, getPrevItem} from "../../../../store/reducer/item";
import {Loading} from "../../../common/Loading";
import {fetchCategories, fetchCategoryDetails} from "../../../../store/actions/v2/categoryActions";
import EditItemForm from "./form";
import {getCategories} from "../../../../store/reducer/category";
import {formValueSelector} from "redux-form";
import {Pagination} from "./_pagination";
import {fetchModels} from "../../../../store/actions/modelActions";
import FlashMessages from './_flashMessages';
import {EbayUrl} from "./_ebayUrl";
import StateTransitionButton from "./_stateTransitionButton";

class EditItem extends React.Component {
    componentDidMount() {
        const {fetchItem, fetchCategories, match: {params: {itemId}}} = this.props;

        fetchItem(itemId);
        fetchCategories();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {item, initialValues, fetchItem} = this.props;
        if ((prevProps.item === undefined && item !== undefined) || (prevProps.item && prevProps.item.id !== item.id)) {
            const category = item.categories[0] || {};
            fetchItem(item.id);
            this.props.fetchModels(category.id, initialValues.brand.brand)
        }
    }


    render() {
        const {
            item, loadStatus, categories, initialValues, options, optionStatus,
            selectedCategory, nextItem, prevItem, variationState, category_locked_selector
        } = this.props;

        if (loadStatus !== 'fetched') {
            return <Loading/>
        }

        return (
            <>
                <div className="columns">
                    <div className="column is-9">
                        <ItemStatus item={item}/>
                        <h1 className="title">{item.name}</h1>
                        <h2 className="subtitle is-5">Seller: {item.seller.username}<EbayUrl item={item}/></h2>
                    </div>
                    <div className="column">
                        <Pagination nextItem={nextItem} prevItem={prevItem}/>
                    </div>
                </div>
                <div className="action-buttons">
                    <StateTransitionButton item={item}/>
                </div>
                <FlashMessages/>

                <EditItemForm
                    item={item}
                    loadStatus={loadStatus}
                    categories={categories}
                    initialValues={initialValues}
                    options={options}
                    optionStatus={optionStatus}
                    selectedCategory={selectedCategory}
                    category_locked_selector={category_locked_selector}
                    nextItem={nextItem}
                    prevItem={prevItem}
                    variationState={variationState}
                />
            </>
        )
    }
}


const mapStateToProps = (state, props) => {
    const item = getItem(state.item, props.match.params.itemId);
    const loadStatus = getItemStatus(state.item);

    if (loadStatus !== 'fetched') {
        return {loadStatus}
    }

    // props for form
    const selector = formValueSelector('updateItemV2');
    let selectedCategory = selector(state, "category") || {};

    const categories = getCategories(state.category);

    let initialCategory = item.categories[0];
    let initialValues = {
        id: item.id,
        category: initialCategory,
        model: {id: item.model_id},
        name: item.name,
        list_price: item.list_price,
        price_retail: item.price_retail,
        description: item.description,
        quantity: item.quantity,
        brand: {},
        details: {},
        accepts_offers: item.accepts_offers,
        variationSelection: item.variations.length > 0 ? "variation" : "single",
        privateDetails: {
            gtin: item.gtin,
            mpn: item.mpn,
            cost: item.cost,
            inventory_id: item.inventory_id
        }
    };

    item.details.filter(f => f.slug !== 'category').forEach(d => {
        if (d.slug === 'brand') {
            initialValues['brand'][d.slug] = d.values[0].id;
        } else {
            initialValues['details'][d.slug] = d.values[0].id;
        }
    });

    item.variations.forEach((variation, index) => {
        variation.details.forEach(detail => {
            variation[detail.type] = detail.slug
        });

        initialValues[`variation-${index + 1}`] = variation
    });

    // setup default parcel info
    if (item.parcel) {
        initialValues.parcel = item.parcel;
        initialValues.parcel_id = item.parcel.id;
    } else if (item.categories[0] && item.categories[0].default_parcel) {
        initialValues.parcel = item.categories[0].default_parcel;
        initialValues.parcel_id = item.categories[0].default_parcel.id;
    }

    let options = state.categoryOptions.categories[selectedCategory.id] || {};
    let optionStatus = state.categoryOptions.status;

    let variationState = {
        itemHasVariations: item.variations.length > 0,
        can_have_variations: options.details && options.details.filter(d => d.has_variations).length > 0,
        detailVariations: options.details && options.details.filter(d => d.has_variations).map(d => d.slug),
        variationSelection: selector(state, 'variationSelection')
    };

    // lock categories if variations exist
    if (variationState.variationSelection === 'variation' && variationState.can_have_variations && variationState.itemHasVariations) {
        initialValues.categories_locked = true
    }

    const category_locked_selector = selector(state, 'categories_locked');

    // pagination
    let nextItem = getNextItem(state.item, item.id);
    let prevItem = getPrevItem(state.item, item.id);

    return {
        item, loadStatus, categories, initialValues, options, optionStatus,
        selectedCategory, nextItem, prevItem, variationState, category_locked_selector
    };
};

const ConnectedEditItem = connect(mapStateToProps, {fetchItem, fetchCategories, fetchCategoryDetails, fetchModels})(EditItem);
export default ConnectedEditItem;
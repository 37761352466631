export const ADD_DETAILS_TO_CATEGORY = "ADD_DETAILS_TO_CATEGORY";
export const ADD_MODELS_TO_BRAND = "ADD_MODELS_TO_BRAND";
export const NO_OPTIONS_FOUND = "NO_OPTIONS_FOUND";

export const addDetailsToCategory = (categoryId, details) => ({
    type: ADD_DETAILS_TO_CATEGORY,
    categoryId,
    details
});


export const addModelsToBrand = (categoryId, models) => ({
    type: ADD_MODELS_TO_BRAND,
    categoryId,
    models
});

export const noOptionsFound = () => ({
    type: NO_OPTIONS_FOUND
});
import { combineReducers } from 'redux';
import {
    FETCH_ITEM,
    FETCH_ITEMS,
    ITEMS_FETCH_FAILURE, SAVE_ITEM,
    SAVE_ITEMS, UPDATE_CURRENT_PAGE,
} from '../../actions/itemActions';
import { produce } from 'immer';
import {FILTER_ITEMS} from "../../actions/filterActions";

const itemList = () => {
    const ids = produce((draft, action) => {
        switch (action.type) {
            case SAVE_ITEMS:
                action.itemList.result.forEach(key => {
                    if (!draft.includes(key)) {
                        draft.push(key);
                    }
                });
                return;
            default:
                return draft;
        }
    }, []);

    const status = (state = null, action) => {
        switch (action.type) {
            case FETCH_ITEMS:
            case FETCH_ITEM:
            case FILTER_ITEMS:
                return "fetching";
            case SAVE_ITEMS:
            case SAVE_ITEM:
            case ITEMS_FETCH_FAILURE:
            case UPDATE_CURRENT_PAGE:
                return "fetched";
            default:
                return state;
        }
    };

    const errorMessage = (state = null, action) => {
        switch (action.type) {
            case ITEMS_FETCH_FAILURE:
                return action.message;
            case FETCH_ITEMS:
            case SAVE_ITEMS:
                return null;
            default:
                return state;
        }
    };

    return combineReducers({
        ids,
        status,

        errorMessage,
    });
};

export default itemList;

export const getIds = state => state.ids;
export const getStatus = state => state.status;
export const getErrorMessage = state => state.errorMessage;

import {produce} from 'immer';
import {SAVE_ITEMS, UPDATE_CURRENT_PAGE} from "../../actions/itemActions";

const initialState = {
    page: 1,
    total_count: 0,
    page_size: 30,
    pages: {}
};


const paginationReducer = produce((draft, action) => {
    switch (action.type) {
        case SAVE_ITEMS:
            const {paging, query} = action;
            draft.total_count = paging.total_count;
            draft.total_pages = paging.total_pages;
            draft.page = paging.page;
            draft.page_size = paging.page_size;
            draft.has_next_page = paging.has_next_page;
            draft.pages[paging.page] = action.itemList.result;
            draft.query = query;
            return;
        case UPDATE_CURRENT_PAGE:
            draft.page = action.page;
            return;
        default:
            return draft;
    }
}, initialState);


export default paginationReducer;
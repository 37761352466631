import {push} from 'connected-react-router';
import {updateUser} from "../actions/loggedInUserActions";
import {catchError, filter, flatMap, map, switchMap} from "rxjs/operators";
import jwt_decode from "jwt-decode";
import {ajax} from "rxjs/ajax";
import {of} from "rxjs";
import {AUTHENTICATION_SUCCEEDED, authenticationFailed, setTokensInCookie} from "../actions/authenticationActions";
import {FETCH_USERS_FOR_FILTER, saveUsersForFilter} from "../actions/userFilterActions";
import {SET_INITIAL_FILTER} from "../actions/filterActions";
import {generateAuthHeaders} from "../../helpers/authHelper";
import {userDetailsUrl, userSearchUrl} from "../urls/userUrls";

const fetchAuthenticatedUser = (action$, state$) =>
    action$.ofType(AUTHENTICATION_SUCCEEDED).pipe(
        switchMap((action) => {
            let access_token = state$.value.authentication.access_token;
            let refresh_token = state$.value.authentication.refresh_token;

            // add tokens to cookie storage
            // if they're already there do i need to do this?
            setTokensInCookie(access_token, refresh_token);

            let decoded = jwt_decode(access_token);
            let uuid = decoded.uuid;

            return ajax.getJSON(userDetailsUrl(uuid), generateAuthHeaders(state$)).pipe(
                flatMap(results =>
                    [
                        updateUser(results.data),
                        push(action.redirectPath)
                    ]
                ),
                catchError(err => {
                    if (err.status === 422) {
                        return of(authenticationFailed())
                    }
                    return of(push("/login"));
                })
            )
        })
    );


const userLookup = (action$, state$) =>
    action$.ofType(FETCH_USERS_FOR_FILTER).pipe(
        switchMap(({term}) => {
            return ajax.getJSON(userSearchUrl(term), generateAuthHeaders(state$)).pipe(
                map(resp => {
                    return saveUsersForFilter(resp.data);
                })
            )
        })
    );

const findUserForFilter = (action$, state$) =>
    action$.ofType(SET_INITIAL_FILTER).pipe(
        filter((action) => action.data.seller),
        switchMap(({data}) => {
            return ajax.getJSON(userSearchUrl(data.seller), generateAuthHeaders(state$)).pipe(
                map(resp => {
                    return saveUsersForFilter(resp.data);
                })
            )
        })
    );

export const userEpics = [
    fetchAuthenticatedUser,
    userLookup,
    findUserForFilter
];
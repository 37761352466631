
export const V2_FETCH_CATEGORIES = "V2_FETCH_CATEGORIES";
export const V2_FETCH_CATEGORY_DETAILS = "V2_FETCH_CATEGORY_DETAILS";
export const V2_SAVE_CATEGORY_DETAILS = "V2_SAVE_CATEGORY_DETAILS";

export const fetchCategories = () => ({
    type: V2_FETCH_CATEGORIES
});

export const fetchCategoryDetails = (itemId) => ({
    type: V2_FETCH_CATEGORY_DETAILS,
    itemId
});

export const saveCategoryDetails = (categoryId, details) => ({
    type: V2_SAVE_CATEGORY_DETAILS,
    categoryId,
    details
});
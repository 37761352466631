import React from 'react';
import LogoTagline from "../common/svg/LogoTagline";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {logout} from "../../store/actions/authenticationActions";

class Navbar extends React.Component {
    render() {
        const {username, logout} = this.props;
        return (
            <nav className="main-navbar navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/"><LogoTagline noTagline/></Link>
                </div>
                <div className="navbar-end">
                    {username ?
                        <>
                            <div className="navbar-item">
                               {username}
                            </div>
                            <span className="navbar-item logout-button" onClick={() => logout()}>Logout</span>
                        </> :
                        <Link to="/login" className="navbar-item">Login</Link>
                    }
                </div>
            </nav>
        )
    }
}

const mapStateToProps = (state) => {
    let username = state.authenticatedUser.user && state.authenticatedUser.user.username;

    return {username}
};
const ConnectedNavbar = connect(mapStateToProps, {logout})(Navbar);
export default ConnectedNavbar;
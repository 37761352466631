import {ADD_VARIATION, CREATE_VARIATION, DELETE_VARIATION, loadItemDetails, UPDATE_VARIATION, V2_FETCH_ITEM} from "../../actions/v2/itemActions";
import {catchError, flatMap, switchMap, map} from "rxjs/operators";
import {of, EMPTY} from 'rxjs';
import {getItem} from "../../reducer/item";
import {ajax} from "rxjs/ajax";
import {createItemVariationUrl, deleteItemVariationUrl, fetchItemUrl, updateItemVariationUrl} from "../../urls/itemUrls";
import {generateAuthHeaders} from "../../../helpers/authHelper";
import {saveItem} from "../../actions/itemActions";
import {push} from "connected-react-router";
import {refreshAuthTokens} from "../../actions/authenticationActions";
import {formValueSelector} from "redux-form";
import {sendFlashMessage} from "../../actions/flashMessageActions";

const fetchItem = (action$, state$) =>
    action$.ofType(V2_FETCH_ITEM).pipe(
        switchMap(action => {
            // does item exist?
            const existingItem = getItem(state$.value.item, action.itemId);

            if (existingItem !== undefined) {
                return of(loadItemDetails(existingItem))
            } else {
                return ajax.getJSON(fetchItemUrl(action.itemId), generateAuthHeaders(state$)).pipe(
                    flatMap(resp => [saveItem(resp.data), loadItemDetails(resp.data)]),
                    catchError(err => {
                        if (err.status === 403) {
                            return of(push("/login"))
                        }
                        if (err.status === 401) {
                            const refresh_token = state$.value.authentication.refresh_token;
                            return of(refreshAuthTokens(refresh_token, `/item/${action.itemId}`, action))
                        }
                    })
                )
            }
        })
    );

const addVariation = (action$, state$) =>
    action$.ofType(ADD_VARIATION).pipe(
        switchMap(action => {
            let item = getItem(state$.value.item, action.itemId);

            const newVariation = {
                id: `variation-${item.variations.length + 1}`,  // temporary id
                name: undefined,
                quantity: undefined,
                details: []
            };

            item.variations.push(newVariation);

            return of(saveItem(item));
        })
    );

const createVariation = (action$, state$) =>
    action$.ofType(CREATE_VARIATION).pipe(
        switchMap(action => {
            const selector = formValueSelector('updateItemV2');
            let variation = selector(state$.value, action.variationIndex) || {};

            variation.quantity = (variation.quantity <= 0 || variation.quantity === undefined) ? 1: variation.quantity

            Object.entries(variation).forEach(([key, value]) => {
                if(['id', 'name', 'quantity', 'details'].includes(key) === false) {
                    variation.details.push(value);
                    delete variation[key]
                }
            });

            return ajax.post(createItemVariationUrl(action.itemId), variation, generateAuthHeaders(state$)).pipe(
                flatMap(resp => {
                    const newVariation = resp.response.data;

                    let item = getItem(state$.value.item, action.itemId);

                    const itemVariationIndex = item.variations.findIndex(v => v.id === action.variationIndex);
                    item.variations[itemVariationIndex] = newVariation;

                    return [saveItem(item),  sendFlashMessage('variationUpdated', 'Variation Successfully Created')];
                }),
                catchError(err => {
                    console.log(err);
                    return EMPTY;
                })
            )
        })
    );


export const  updateVariationObservable = (itemId, variation, state$, ignoreFlashMessages = false)  => {
    return ajax.put(updateItemVariationUrl(itemId, variation.id), variation, generateAuthHeaders(state$)).pipe(
        flatMap(resp => {
            const updatedVariation = resp.response.data;

            let item = getItem(state$.value.item, itemId);

            const itemVariationIndex = item.variations.findIndex(v => v.id === updatedVariation.id);
            item.variations[itemVariationIndex] = updatedVariation;

            let actions = [saveItem(item)];

            if(ignoreFlashMessages === true) {
                return actions
            } else {
                actions.push(sendFlashMessage('variationUpdated', 'Variation Successfully Updated'))
                return actions;
            }
        }),
        catchError(err => {
            console.log(err);
            return EMPTY;
        })
    )
};

const updateVariation = (action$, state$) =>
    action$.ofType(UPDATE_VARIATION).pipe(
        switchMap(action => {
            const selector = formValueSelector('updateItemV2');
            let variation = selector(state$.value, action.variationIndex) || {};

            // clear out old details so the API knows what to do
            variation.details = [];
            Object.entries(variation).forEach(([key, value]) => {
                if(['id', 'name', 'quantity', 'details'].includes(key) === false) {
                    variation.details.push(value);
                    delete variation[key]
                }
            });
            return updateVariationObservable(action.itemId, variation, state$);
        })
    );

function deleteVariationFromItem(state$, action) {
    let item = getItem(state$.value.item, action.itemId);

    const itemVariationIndex = item.variations.findIndex(v => v.id === action.variationId);

    if (itemVariationIndex !== -1) {
        item.variations.splice(itemVariationIndex, 1);
    }
    return item;
}

const deleteVariation = (action$, state$) =>
    action$.ofType(DELETE_VARIATION).pipe(
        switchMap(action => {
            // if variation id is a number, delete it at the server
            if(Number.isInteger(action.variationId)) {
                return ajax.delete(deleteItemVariationUrl(action.itemId, action.variationId), generateAuthHeaders(state$)).pipe(
                    map(resp => {
                        let item = deleteVariationFromItem(state$, action);

                        return saveItem(item);
                    }),
                    catchError(err => {
                        console.log(err);
                        return EMPTY;
                    })
                )
            } else {
                // it is a temporary (unsaved) variation
                let item = deleteVariationFromItem(state$, action);

                return of(saveItem(item));
            }
        })
    );

export const v2ItemEpics = [
    fetchItem,
    addVariation,
    updateVariation,
    deleteVariation,
    createVariation
];
import {catchError, map, switchMap} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {fetchDetailUrl} from "../../urls/detailUrls";
import {generateAuthHeaders} from "../../../helpers/authHelper";
import {addDetailsToCategory, noOptionsFound} from "../../actions/categoryOptionActions";
import {of} from "rxjs";
import {push} from "connected-react-router";
import {V2_LOAD_ITEM_DETAILS} from "../../actions/v2/itemActions";


const fetchDetails = (action$, state$) =>
    action$.ofType(V2_LOAD_ITEM_DETAILS).pipe(

        switchMap(({item}) => {
            const category = item.categories[0];

            if(category === undefined) {
                return of(noOptionsFound());
            }



                return ajax.getJSON(fetchDetailUrl(category.id), generateAuthHeaders(state$)).pipe(
                    map((resp) =>
                        addDetailsToCategory(category.id, resp.data)
                    ),
                    catchError(err => {
                        if (err.status === 401) {
                            return of(push("/login"))
                        }
                    })
                )
            }
        )
    );



export const v2DetailEpics = [
    fetchDetails
];
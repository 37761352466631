import { combineReducers } from 'redux';
import byId, * as fromById from './byId';
import itemList, * as fromList from './list';
import isEmpty from 'lodash/isEmpty';

export default combineReducers({
    byId,
    itemList: itemList(),
});

export const getItem = (state, id) => fromById.getItem(state.byId, id);

export const getItems = (state, ids = []) => {
    if (!ids.length) {
        ids = fromList.getIds(state.itemList);
    }
    if (isEmpty(state.byId)) {
        return [];
    }

   return ids.map(id => fromById.getItem(state.byId, id));
};

export const getItemStatus = state =>
    fromList.getStatus(state.itemList);

export const getErrorMessage = state =>
    fromList.getErrorMessage(state.itemList);


export const getNextItem = (state, id) => {
    const idx = state.itemList.ids.findIndex(i => i === id);

    const itemId = state.itemList.ids[idx +1];

    return getItem(state, itemId)
};

export const getPrevItem = (state, id) => {
    const idx = state.itemList.ids.findIndex(i => i === id);

    const itemId = state.itemList.ids[idx - 1];


    return getItem(state, itemId)
};
import React from 'react';
import {Field} from "redux-form";
import renderInput from "../../../../common/form/renderInput";
import PricingInfo from "./_pricing";

class GeneralInformation extends React.Component {
    render() {
        const {item, variationState} = this.props;
        return (
            <div className="box">
                <h2 className="subtitle is-4">General Information</h2>
                <Field name="name" label="Title" component={renderInput}/>

                <PricingInfo item={item} variationState={variationState}/>

                <div className="field">
                    <label className="label">How would you describe it?</label>
                    <Field component="textarea" className="textarea" name="description" rows="8"/>
                </div>
            </div>
        );
    }
}

export default GeneralInformation
import {getCategoryStatus} from "../../reducer/category";
import {V2_FETCH_CATEGORIES} from '../../actions/v2/categoryActions';
import {filter, flatMap, map, reduce, switchMap} from 'rxjs/operators';
import {ajax} from "rxjs/ajax";
import {fetchCategoryUrl} from "../../urls/categoryUrls";
import {generateAuthHeaders} from "../../../helpers/authHelper";
import {saveCategories} from "../../actions/categoryActions";

const fetchCategories = (action$, state$) =>
    action$.ofType(V2_FETCH_CATEGORIES).pipe(
        filter(action => {
            const categoryStatus = getCategoryStatus(state$.value.category);
            return categoryStatus !== 'fetched'
        }),
        switchMap(action => {
                return ajax.getJSON(fetchCategoryUrl(), generateAuthHeaders(state$)).pipe(
                    flatMap((resp) =>
                        resp.data
                    ),
                    reduce((categories, category) => {
                        if (category.relatable) {
                            categories.push(category);
                        }

                        if (category.children) {
                            category.children.forEach(child => {
                                if (child.relatable) {
                                    categories.push(child);
                                }


                                if (child.children) {
                                    child.children.forEach(child2 => {
                                        if (child2.relatable) {
                                            categories.push(child2)
                                        }
                                    })
                                }
                            });
                        }

                        return categories
                    }, []),
                    map(categories =>
                        saveCategories(categories)
                    ),
                )
            }
        )
    );


export const v2CategoryEpics = [
    fetchCategories
];
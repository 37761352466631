export const FETCH_LOGGED_IN_USER = "FETCH_LOGGED_IN_USER";
export const UPDATE_USER = "UPDATE_USER";

export const fetchLoggedInUser = () => ({
    type: FETCH_LOGGED_IN_USER
});

export const updateUser = (data) => ({
    type: UPDATE_USER,
    data
});
import React from 'react';
import {connect} from "react-redux";
import {requestItemPublish, requestItemUpdate} from "../../../../../store/actions/itemActions";
import StateTransitionButton from "../_stateTransitionButton";
import {Link, withRouter} from "react-router-dom";
import {getItem} from "../../../../../store/reducer/item";

class FormActions extends React.Component {
    render() {
        const {item, itemState, handleSubmit, requestItemUpdate, requestItemPublish} = this.props;

        return (
            <div className="columns">
                <div className="column is-fullwidth">
                    <div className="box last-element action-buttons">
                        <div className="field is-grouped">

                            {['available', 'draft'].includes(itemState) && (
                                <div className="control">
                                    <button className="button is-link is-medium" onClick={handleSubmit(requestItemUpdate)}>Update
                                        Item
                                    </button>
                                </div>
                            )}

                            {['draft'].includes(itemState) && (
                                <div className="control">
                                    <button className="button is-link is-medium" onClick={handleSubmit(requestItemPublish)}>Update
                                        & Publish Item
                                    </button>
                                </div>
                            )}

                            <div className="control">
                                <StateTransitionButton item={item}/>
                            </div>

                            <div className="control">
                                <Link to="/" className="button is-text is-medium">Cancel</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const item = getItem(state.item, props.match.params.itemId);

    return {
        item,
        itemState: item.state
    }
};
const ConnectedFormActions = withRouter(connect(mapStateToProps, {requestItemUpdate, requestItemPublish})(FormActions));
export default ConnectedFormActions;